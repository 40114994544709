<template>
  <div @click="navigateToHome()">
    <button>Click me</button>
  </div>
</template>

<script>
export default {
  methods: {
    navigateToHome() {
      this.$router.push({ path: "/home", query: { id: "au" } });
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
