<template>
    <v-container class="d-flex align-center justify-center" style="height:100vh;">
        <div class="p-5 Error-section flight-search">

            <div>
                <div class="mb-5">
                    <!-- <v-img class="m-auto" :src="require('@/assets/buymytrip.png')" width="250px"></v-img> -->
                </div>

                <div class="mb-5">
                    <p class="error-content-1">No flights offered by airlines included on our website matches you search
                        criteria</p>
                    <p class="error-content-2">Please check flights from other airports or change your search request</p>
                </div>

                <div class="d-flex justify-center">
                    <!-- <v-btn @click="added()" color="#002d5b" style="text-transform: none;">Change your search</v-btn> -->
                    <button type="submit " class="btn btn-search" @click="added()"
                        style="width: 300px; height:40px; min-width: 0; min-height: 0;">
                    <span class="fw-bold" style="font-size:16px;">Change your search</span>
                  </button>
                </div>
            </div>
        </div>
    </v-container>
        
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        added() {
            this.$router.push('/')
           document.getElementById("navbar").style.display = "block";
            document.getElementById("food").style.display = "block";
        }
    }
}



</script>

<style scoped>


.Error-section{
    background-color:#ffffffd9; 
    border-radius:20px;
}
.error-content-1 {
    font-size: 32px;
    font-family: 'Roboto';
    text-align: center;
    padding: 0px 20px;
}

.error-content-2 {
    font-size: 26px;
    font-family: 'Roboto';
    text-align: center;
    padding: 0px 20px;

}
</style>