<template>

<div class="content-section" v-if="!pageloader">
        <!-- flight result page - oneway start -->
        <div class="review-flight py-5">
            <div class="container">
                <div class="row myBookingCard">
                    <div class="col-12 col-md-6 offset-md-3">
                        <!-- travel insurance section -->
                        <div class="theme-box-shadow theme-border-radius theme-bg-white p-3">
                            <div class="d-flex align-items-center mb-3">
                                <div class="flex-shrink-0">
                                    <div class="theme-bg-primary p-3 rounded-circle">
                                        <i class="bi bi-journal-text lh-1 fs-4"></i>
                                    </div>

                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span class="fs-4 fw-bold">VIEW YOUR BOOKING</span>
                                    <!-- <p class="font-medium mb-0 theme-text-accent-one">Sign in and Get lots of
                                        Cashback Rewards and
                                        Discount
                                    </p> -->
                                </div>
                            </div>
                            <div class="col-12">

                                <v-form  v-model="valid" ref="form" @submit.prevent="bookinglist">
                                <!-- <form class="needs-validation" novalidate> -->
                                    <div class="mb-1">
                                        <label for="exampleInputEmail1" class="form-label">Booking ID</label>
                                        <!-- <v-text-field v-model="loginuser.userEmail" :rules="eMail" 
                                    variant="outlined" placeholder="Email"
                                    style="height:55px;font-size: 15px !important"></v-text-field> -->
                                    <v-text-field placeholder="Booking ID" v-model="bookid" 
                                    :rules="[(v) => !!v || 'Enter the Booking ID']" variant="outlined"></v-text-field>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Last Name</label>
                                        <!-- <v-text-field v-model="loginuser.userPass" :rules="uPass"
                                    placeholder="password" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                    style="height:55px;font-size: 15px !important"></v-text-field> -->
                                    <v-text-field  :rules="[(v) => !!v || 'Enter the Last Name']" v-model="lastname"
                                    variant="outlined" placeholder="Last Name" @input="capitalizeLastName" ></v-text-field>
                                    </div>
                                    <div class="mb-3 mx-auto">
                                        <button type="submit" class="btn btn-effect btn-book px-5 min-h58" :class="{'pe-none' : myBookLoad}">
                                            <v-progress-circular v-if="myBookLoad" :width="5" color="white" indeterminate></v-progress-circular>
                                            {{ !myBookLoad ? 'Submit' : '' }}</button>
                                        
                                        <span class="font-medium">
                                            <span class="ms-3 me-2 text-uppercase">or</span>
                                            <a href="/" class="">Return to Home</a>
                                        </span>
                                    </div>
                                <!-- </form> -->

                                </v-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    


  <div style="background-color:#d3d3d34a; display: none;">
  <div class="trip">VIEW YOUR BOOKING</div>
  <v-form v-model="valid" ref="form" @submit.prevent="bookinglist">
    <div class="pre-card">
      <v-card class="mybookCard">
        <div class="col-12">
          <v-text-field
            label="Booking ID"
            v-model="bookid"
            :rules="[(v) => !!v || 'Enter the Booking ID']"
            variant="outlined"
          ></v-text-field>
        </div>

        <div class="col-12">
          <v-text-field
            label="Last Name"
            :rules="[(v) => !!v || 'Enter the Last Name']"
            v-model="lastname"
            variant="outlined"
            @input="capitalizeLastName"
          ></v-text-field>
        </div>

        <div class="col-12">
          <!-- <v-btn
            type="submit"
            width="100%"
            style="height: 45px !important"
            color="#ff6b0a"
            >SUBMIT</v-btn> -->

            <button type="submit"  class="btn btn-search"><span class="fw-bold">SUBMIT</span></button>
        </div>
      </v-card>
    </div>
  </v-form>

  <div>
    <v-dialog v-model="dialog">
      <v-card>
        <v-container>
          <div class="d-flex justify-end">
            <v-icon @click="circle()" size="35" color="red">
              mdi-close-circle-outline
            </v-icon>
          </div>

          <div>
            <div class="mt-4">
              <v-card class="mt-3 p-2">
                <div>
                  <div class="row d-flex justify-between">
                    <div class="col-md-8 col-6">
                      <span class="Booking-id-head">BuyMyTrip Booking ID:</span>
                      <div class="d-flex align-center mb-2">
                        <span
                          class="Booking-id"
                          style="text-transform: uppercase"
                          >{{ tripId }}</span
                        >
                        <v-icon class="ms-4 me-2" size="12px" color="warning"
                          >mdi-circle</v-icon
                        >
                        <span
                          style="font-size: 13px; text-transform: uppercase"
                          >{{ bookingStatus }}</span
                        >
                      </div>
                    </div>

                    <div class="col-md-2 col-3 d-flex justify-end">
                      <v-btn @click="modify()" color="#ff6b0a">Modify</v-btn>
                    </div>

                    <div class="col-md-2 col-3 d-flex justify-end">
                      <!-- style="color: green;outline: 1px solid;width: 130px;height: 35px; border-radius: 4px;" -->
                      <v-btn
                        class="print-btn-1"
                        prepend-icon="mdi-printer"
                        @click="seleceprint()"
                        >Print</v-btn
                      >
                      <v-icon class="print-btn-2" @click="seleceprint()"
                        >mdi-printer</v-icon
                      >
                    </div>
                  </div>
                </div>
              </v-card>
            </div>

            <div class="mt-3" id="printMe">
              <v-card class="p-2">
                <div
                  style="
                    border: 1px solid grey;
                    border-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row align-center">
                    <div class="col-md-2 col-6 my-sm-0 my-2 order-sm-1 order-2">
                      <p class="m-0" style="font-size: 18px; font-weight: 500">
                        Trip ID #
                      </p>
                      <span>{{ tripId }}</span>
                    </div>
                    <div class="col-md-2 col-6 my-sm-0 my-2 order-sm-2 order-3">
                      <p class="m-0" style="font-size: 18px; font-weight: 500">
                        Booking Date
                      </p>
                      <span>{{ bookingTime }}</span>
                    </div>
                    <div
                      class="col-md-4 col-12 my-sm-0 my-2 order-sm-3 order-4"
                      align="center"
                    >
                      <div class="row">
                        <div class="col-6">
                          {{ getdepcode(depatureData) }}
                          <br />
                          {{ getstarttime(depatureData) }}
                        </div>
                        <div class="col-6">
                          {{ getarrcode(depatureData) }}
                          <br />
                          <span v-if="datareurn">{{
                            getstarttime1(arrivalData)
                          }}</span>
                        </div>
                      </div>

                      <!-- <p class="m-0 text-start" style="font-size: 18px; font-weight: 500">
                   {{ getdepcode(depatureData) }} -
                   {{ getarrcode(depatureData) }}
                 </p>
                 <p class="m-0 text-start">
                     {{ getstarttime(depatureData)}} 
                    <span v-if="datareurn">-{{ getstarttime1(arrivalData)}}</span>
                 </p> -->
                    </div>

                    <div
                      class="col-md-2 col-12 my-sm-0 my-2 d-flex flex-sm-column flex-row order-sm-4 order-4"
                    >
                      <div class="d-flex">
                        <span style="margin-right: 10px">&#9679;</span>
                        <p class="m-0">{{ triptype }}</p>
                      </div>
                      <div class="d-flex ps-2 ps-sm-0">
                        <span style="margin-right: 10px">&#9679;</span>
                        <p class="m-0" style="text-transform: uppercase">
                          {{ searchcabintype }}
                        </p>
                      </div>
                    </div>

                    <div class="col-md-2 order-sm-5 order-1">
                      <v-img
                        width="130px"
                        :src="require('@/assets/buymytrip.png')"
                        style="cursor: pointer"
                      />
                    </div>
                  </div>
                </div>
              </v-card>

              <v-card class="p-2 mt-3">
                <div>
                  <div>
                    <div class="row mt-2">
                      <h5 class="col-12 col-md-6">
                        <v-icon color="grey">mdi-ticket</v-icon>
                        ITINERARY
                      </h5>
                    </div>

                    <v-table
                      class="text-center rounded desktop-table"
                      style="border: 1px solid lightgray"
                    >
                      <thead>
                        <tr>
                          <th class="text-center text-dark">PASSENGER NAME</th>
                          <th class="text-center text-dark">GENDER</th>
                          <th class="text-center text-dark">DOB</th>
                          <th class="text-center text-dark">PAX TYPE</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(datas1, index) of passengerDetail"
                          :key="index"
                        >
                          <td
                            class="text-center text-dark f-family"
                            style="text-transform: uppercase"
                          >
                            {{ datas1.firstname }}
                            {{ datas1.middlename }} {{ datas1.lastname }}
                            <span style="text-transform: capitalize">{{
                              datas1.salutation
                            }}</span>
                          </td>
                          <td
                            class="text-center text-dark f-family"
                            style="text-transform: uppercase"
                          >
                            {{ datas1.gender }}
                          </td>
                          <td class="text-center text-dark f-family">
                            {{ getdob(datas1.dob) }}
                          </td>
                          <td
                            class="text-center text-dark f-family"
                            style="text-transform: uppercase"
                          >
                            {{ datas1.pax_type }}
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </div>
                </div>

                <div class="mt-3" style="margin-left: 35px">
                  <h5>Contact Info</h5>
                  <div class="row mt-3">
                    <div class="col-md-4">
                      <span class="fw-600">Phone : </span>
                      <span class="f-family"
                        >{{ countrycode }} {{ contactnum }}</span
                      >
                    </div>
                    <div class="col-md-4">
                      <span class="fw-600">Email : </span>
                      <span class="f-family"> {{ passEmail }}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <v-card class="mt-2 px-2" style="box-shadow: none">
                      <hr
                        class="mb-sm-4 mb-0 mt-0"
                        style="margin-bottom: 0px !important"
                      />
                      <div
                        class="row d-flex justify-content-between align-center pt-2 pb-2"
                      >
                        <div class="col-md-4 col-12">
                          <h6 class="m-0" style="font-size: 18px">
                            <v-icon
                              color="grey"
                              style="
                                font-size: 24px;
                                transform: rotate(90deg) !important;
                              "
                            >
                              mdi-airplane
                            </v-icon>
                            <span> {{ getdepcode(depatureData) }} </span>-
                            {{ getarrcode(depatureData) }}
                            <span style="font-size: 15px">
                              <!-- ( {{ data.Depaturejourney.Time }},
                           {{ getStop(data.Depature) }} Stops ) -->
                            </span>
                          </h6>
                        </div>
                        <div class="col-md-6 col-12 mt-1 mt-sm-0">
                          <h6 class="m-0">
                            <span>
                              <v-icon class="me-1" color="grey"
                                >mdi-calendar</v-icon
                              ></span
                            >
                            {{ getstarttime(depatureData) }}
                          </h6>
                        </div>
                      </div>
                      <hr
                        class="mb-0 mt-sm-4 mt-0"
                        style="margin-top: 0px !important"
                      />
                      <v-container style="max-width: 100%">
                        <div v-for="(data, index) of depatureData" :key="index">
                          <div class="row mt-1 g-0 mb-1">
                            <div class="col-md-4 col-3 mb-1 mb-md-0">
                              <div class="row align-end w-100">
                                <div class="col-md-4 col-12 airline-logo">
                                  <v-img
                                    :src="data.logo"
                                    class="airlogo-size"
                                    width="40px"
                                  ></v-img>
                                </div>
                                <div class="col-md-8 col-12 airline-profile">
                                  <p class="f-family airline-name m-0">
                                    <span class="f-family airline-name">{{
                                      data.flightname
                                    }}</span>
                                    <br />
                                    <span
                                      class="f-family airline-name airline-id"
                                    >
                                      {{ data.carrier }} -
                                      {{ data.flight_number }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-9">
                              <div class="row d-flex">
                                <div class="col-sm-3 p-0">
                                  <span class="f-family airline-date">
                                    {{ getdepdate(data.depart_datetime) }}
                                  </span>
                                  <span
                                    class="f-family airline-time"
                                    style="margin-left: 5px"
                                  >
                                    {{ getdeptime(data.depart_datetime) }}
                                  </span>
                                </div>
                                <div
                                  class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0"
                                  style="margin-bottom: 4px"
                                >
                                  <span class="fw-500">
                                    {{ data.depart_airport }} </span
                                  >,
                                  {{ data.depAirportName }}
                                </div>
                              </div>

                              <div class="row d-flex">
                                <div class="col-sm-3 p-0">
                                  <span class="f-family airline-date">
                                    {{ getarrdate(data.arrival_datetime) }}
                                  </span>
                                  <span
                                    class="f-family airline-time"
                                    style="margin-left: 5px"
                                  >
                                    {{ getarrtime(data.arrival_datetime) }}
                                  </span>
                                </div>

                                <div
                                  class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0"
                                  style="margin-bottom: 4px"
                                >
                                  <span class="fw-500">{{
                                    data.arrival_airport
                                  }}</span
                                  >,

                                  {{ data.arrAirportName }}
                                </div>
                              </div>
                            </div>

                            <!-- <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                           <div class="d-flex align-center class-type">
                             <v-icon size="15px" color="#ccc">
                               mdi-seat-passenger
                             </v-icon>
                            
                             <span class="f-family" style="padding-left: 5px;text-transform:uppercase">
                               {{ data.cabin_type }}
                             </span>
                           </div>
 
                         
                         </div> -->
                          </div>

                          <div class="d-flex position-relative">
                     <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                     <div class="d-flex justify-center" v-if="depatureData.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                       <div class="d-flex align-center">
                         <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                       </div>
                     </div>
                   </div>
                      </div>
                      </v-container>
                    </v-card>
                  </div>

                  <!-- Return Flight Start -->

                  <div class="mt-1" v-if="datareurn">
                    <v-card
                      class="mt-1 px-2"
                      style="box-shadow: none"
                      v-if="arrivalData.length !== 0"
                    >
                      <hr
                        class="mb-sm-4 mb-0 mt-0"
                        style="margin-bottom: 0px !important"
                      />
                      <div
                        class="row d-flex justify-content-between align-center pt-2 pb-2"
                      >
                        <div class="col-md-4 col-12">
                          <h6 class="m-0" style="font-size: 18px">
                            <v-icon
                              color="grey"
                              style="
                                font-size: 24px;
                                transform: rotate(90deg) !important;
                              "
                            >
                              mdi-airplane
                            </v-icon>
                            <span> {{ getdepcode1(arrivalData) }} </span>-
                            {{ getarrcode1(arrivalData) }}
                            <span style="font-size: 15px">
                              <!-- ( {{ data.Depaturejourney.Time }},
                       {{ getStop(data.Depature) }} Stops ) -->
                            </span>
                          </h6>
                        </div>
                        <div class="col-md-6 col-12 mt-1 mt-sm-0">
                          <h6 class="m-0">
                            <span>
                              <v-icon class="me-1" color="grey"
                                >mdi-calendar</v-icon
                              ></span
                            >
                            {{ getstarttime1(arrivalData) }}
                          </h6>
                        </div>
                      </div>
                      <hr
                        class="mb-0 mt-sm-4 mt-0"
                        style="margin-top: 0px !important"
                      />
                      <v-container style="max-width: 100%">
                        <div v-for="(data, index) of arrivalData" :key="index">
                          <div class="row mt-3 g-0 mb-2">
                            <div class="col-md-4 col-3 mb-3 mb-md-0">
                              <div class="row align-end w-100">
                                <div class="col-md-4 col-12 airline-logo">
                                  <v-img
                                    :src="data.logo"
                                    class="airlogo-size"
                                    width="40px"
                                  ></v-img>
                                </div>
                                <div class="col-md-8 col-12 airline-profile">
                                  <p class="f-family airline-name m-0">
                                    <span class="f-family airline-name">{{
                                      data.flightname
                                    }}</span>
                                    <br />
                                    <span
                                      class="f-family airline-name airline-id"
                                    >
                                      {{ data.carrier }} -
                                      {{ data.flight_number }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 col-9">
                              <div class="row d-flex">
                                <div class="col-sm-3 p-0">
                                  <span class="f-family airline-date">
                                    {{ getdepdate1(data.depart_datetime) }}
                                  </span>
                                  <span
                                    class="f-family airline-time"
                                    style="margin-left: 5px"
                                  >
                                    {{ getdeptime1(data.depart_datetime) }}
                                  </span>
                                </div>
                                <div
                                  class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0"
                                  style="margin-bottom: 4px"
                                >
                                  <span class="fw-500">
                                    {{ data.depart_airport }} </span
                                  >,
                                  {{ data.depAirportName }}
                                </div>
                              </div>

                              <div class="row d-flex">
                                <div class="col-sm-3 p-0">
                                  <span class="f-family airline-date">
                                    {{ getarrdate1(data.arrival_datetime) }}
                                  </span>
                                  <span
                                    class="f-family airline-time"
                                    style="margin-left: 5px"
                                  >
                                    {{ getarrtime1(data.arrival_datetime) }}
                                  </span>
                                </div>

                                <div
                                  class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0"
                                  style="margin-bottom: 4px"
                                >
                                  <span class="fw-500">{{
                                    data.arrival_airport
                                  }}</span
                                  >,

                                  {{ data.arrAirportName }}
                                </div>
                              </div>
                            </div>
                            <!--  
                     <div class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box">
                       <div class="d-flex align-center class-type">
                         <v-icon size="15px" color="#ccc">
                           mdi-seat-passenger
                         </v-icon>
 
                         <span class="f-family" style="padding-left: 5px;text-transform:uppercase">
                           {{ data.cabin_type }}
                         </span>
                       </div>
 
                     
                     </div> -->
                          </div>

             
    <div class="d-flex position-relative">
                     <hr v-if="arrivalData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                     <div class="d-flex justify-center" v-if="arrivalData.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                       <div class="d-flex align-center">
                         <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                       </div>
                     </div>
                   </div>
                        </div>
                      </v-container>
                    </v-card>
                  </div>
                </div>
              </v-card>
            </div>

            <!-- <div class="mt-3">
         <v-card class="px-2">
 
         </v-card>
       </div> -->

            <div class="mt-1" id="printMe">
              <v-card class="p-2">
                <div>
                  <div>
                    <div class="row mt-1">
                      <h5 class="col-12 col-md-6">
                        <v-icon color="grey">mdi-cash-multiple</v-icon>
                        FARE SUMMARY
                      </h5>
                    </div>

                    <div class="mt-1">
                      <v-card class="m-2 px-2">
                        <div class="row mt-3">
                          <div class="col-md-4 d-flex">
                            <span class="fw-bold f-family"
                              >Ticket Price per person:
                            </span>
                            <span class="ps-2 f-family">
                              {{ currency }} {{ baseprice }}</span
                            >
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-md-4 d-flex">
                            <h5>Total Price:</h5>
                            <h5 style="margin-left: 5px">
                              {{ currency }} {{ totalprice }}
                            </h5>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
              </v-card>
              <div class="mt-4">
                <v-card class="p-3">
                  <div class="row" id="printMe">
                    <div class="col-md-6 col-sm-6">
                      <h6>TERMS & CONDITIONS</h6>
                    </div>
                    <div class="col-sm-6 col-md-6 contion">
                      <a href="#" style="font-size: 13px">
                        Show Tearms & Conditions</a
                      >
                    </div>
                  </div>

                  <div
                    style="
                      border: 1px solid grey;
                      padding: 10px;
                      border-radius: 10px;
                    "
                    class="BuyMyTrip"
                  >
                    <p>
                      BuyMyTrip offers you, our customer, access to and use of
                      our site subject to your acceptance without modification
                      of the Terms and Conditions contained herein. By using or
                      obtaining any content, materials, data, information,
                      product BuyMyTrip, or services through our site, you agree
                      to the following Terms:
                    </p>
                    <p>
                      Unaccompanied Minors: Children age 5 through 14 years may
                      be required to use the airline's Unaccompanied Minor
                      service. Additionally, you may be required to pay a fee to
                      use this service, which is usually charged at check-in
                      with specific airlines. Unaccompanied minor policies and
                      fees vary by airline; please check with your airline for
                      specific policies and fees.
                    </p>

                    <p>
                      Airline Fees: We reserve the right to charge a processing
                      fee for issuance of your airline ticket, car rental, hotel
                      reservation, cruise, or tour booking.
                    </p>
                    <p>
                      EXCURSION AND PROMOTIONAL FARES. Most discount fares
                      involve restrictions. A change in carrier(s), flight(s),
                      time(s), or routing(s) could result in a carrier demanding
                      a full fare. Obtain agency or airline assistance before
                      making changes. If your plans change enroute, apply the
                      value of your tickets toward a re-issued one. It is not
                      necessary to purchase a new ticket. If a refund is due,
                      obtain a receipt from the airline. Departure times,
                      flights and fares are based on current tariffs that are
                      subject to change without notice.
                    </p>

                    <p>
                      Changes, Voids, Cancellations: BuyMyTrip This is in
                      addition to the airlines'exchange fees. The airline rules
                      will be quoted to the customer before initiating an
                      exchange. Additionally, some airline tickets issued are
                      non-refundable and nonexchangeable. There may also be a
                      fee assessed in the event of a refund or cancellation of
                      the previously above stated, imposed by the Travel
                      Supplier. Refunds from suppliers will be made to our
                      customers only after we have received the money from those
                      sources (supplier rules and restrictions will apply).
                    </p>
                    <p>
                      LIMITATION. You may use our web site for personal and
                      non-commercial purposes only. You may not modify, copy,
                      distribute, transmit, display, reproduce, publish,
                      license, create derivative works from, transfer, upload,
                      post or sell any information, software, product BuyMyTrip,
                      or services obtained from this web site. Trademarks, logos
                      and service marks displayed on our site are ours, and our
                      suppliers' registered and common law marks. Use of and
                      access to our site does not grant you any license or right
                      to use any of the trademarks.
                    </p>
                    <p>
                      LIABILITY DISCLAIMER. ALL INFORMATION, CONTENT, DATA,
                      MATERIALS, Product BuyMyTrip, AND SERVICES ARE PROVIDED ON
                      AN 'AS IS' BASIS. BuyMyTrip AND ITS SUPPLIERS MAKE NO
                      WARRANTY OF ANY KIND REGARDING OUR SITE. ALL INFORMATION,
                      PRICES, AND AVAILABILITY ARE SUBJECT TO CHANGE WITHOUT
                      PRIOR NOTICE. WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR
                      WARRANTY THAT OUR SITE WILL BE ERROR-FREE, OR
                      UNINTERRUPTED. ANY WARRANTY AS TO THE ACCURACY,
                      COMPLETENESS AND TIMELINESS OF CONTENT ON OUR SITE IS
                      FURTHER DISCLAIMED. WE SHALL NOT BE RESPONSIBLE FOR BREACH
                      OF CONTRACT OF ANY INTENTIONAL OR CARELESS ACTIONS OR
                      OMISSIONS ON THE PART OF SUCH SUPPLIERS, WHICH RESULT IN
                      ANY LOSS, DAMAGE, DELAY, OR INJURY TO YOU OR YOUR
                      TRAVELING COMPANIONS OR GROUP MEMBERS. UNLESS THE TERM
                      'GUARANTEED' IS SPECIFICALLY STATED IN WRITING ON YOUR
                      TICKETS, INVOICE, OR RESERVATION ITINERARY, WE DO NOT
                      GUARANTEE ANY OF SUCH SUPPLIERS' RATES, BOOKINGS,
                      RESERVATIONS, CONNECTIONS, SCHEDULING, OR HANDLING OF
                      PERSONAL EFFEBuyMyTrip AND LUGGAGE. WE SHALL NOT BE
                      RESPONSIBLE FOR ANY INJURIES, DAMAGES, OR LOSSES CAUSED TO
                      ANY TRAVELER IN CONNECTION WITH TERRORIST ACTIVITIES,
                      SOCIAL OR LABOR UNREST, MECHANICAL OR CONSTRUCTION
                      FAILURES OR DIFFICULTIES, DISEASES, LOCAL LAWS, CLIMATIC
                      CONDITIONS, ABNORMAL CONDITIONS OR DEVELOPMENTS, OR ANY
                      OTHER ACTIONS, OMISSIONS, OR CONDITIONS OUTSIDE OUR
                      CONTROL. THE TRAVELER ASSUMES COMPLETE AND FULL
                      RESPONSIBILITY FOR, AND HEREBY RELEASES THE AGENT FROM,
                      ANY DUTY OF CHECKING AND VERIFYING ANY AND ALL PASSPORT,
                      VISA, VACCINATION, OR ANY OTHER ENTRY REQUIREMENTS FOR
                      EACH DESTINATION, AND ALL SAFETY AND SECURITY CONDITIONS
                      OF SUCH DESTINATIONS, DURING THE LENGTH OF THE PROPOSED
                      TRAVEL. FOR INFORMATION CONCERNING POSSIBLE DANGERS AT
                      INTERNATIONAL DESTINATIONS, CONTACT THE TRAVEL ADVISORY
                      SECTION OF THE U.S. STATE DEPARTMENT AT 202.647.5225. FOR
                      MEDICAL INFORMATION, CALL THE US CENTER FOR DISEASE AT
                      404.332.4559 OR VISIT THEIR WEBSITE, www.cdc.gov. BY
                      EMBARKING UPON HIS/HER TRAVEL, THE TRAVELER VOLUNTARILY
                      ASSUMES ALL RISKS INVOLVED IN SUCH TRAVEL, WHETHER
                      EXPECTED OR UNEXPECTED. THE TRAVELER IS HEREBY WARNED OF
                      THE ABOVE RISKS, AS WELL AS POSSIBLE TRAVEL INDUSTRY
                      BANKRUPTCIES AND MEDICAL AND CLIMATIC DISRUPTIONS, AND IS
                      ADVISED TO OBTAIN APPROPRIATE INSURANCE COVERAGE AGAINST
                      THEM, WHICH IS AVAILABLE AT AN EXTRA COST THROUGH
                      BuyMyTrip . THE TRAVELER'S RETENTION OF TICKETS,
                      RESERVATIONS, TOUR DOCUMENTS, CRUISE DOCUMENTS, TRAVEL
                      AGENCY INVOICE OR BOOKINGS AFTER ISSUANCE SHALL CONSTITUTE
                      A CONSENT TO THE ABOVE AND AN AGREEMENT ON HIS/HER PART TO
                      CONVEY THE CONTENTS HERETO TO HIS/HER TRAVEL COMPANIONS OR
                      GROUP TRAVEL MEMBERS.
                    </p>
                    <p>
                      LINKS. Our site contains links to other sites, which we do
                      not control or operate. These links are provided for your
                      convenience and reference. You are free to access these
                      other sites, but you do so at your own risk.
                    </p>
                    <p>
                      PRIVACY. Use of our site is subject to our Privacy Policy.
                      Acceptance of our Terms and Conditions warrants acceptance
                      of our Privacy Policy.
                    </p>

                    <p>
                      IMPORTANT. Please check your travel documents when you
                      receive them. You may call us at Phone: +1 6592512000 Toll
                      Free with any questions.
                    </p>
                    <p>
                      CHECK-IN. Minimum check-in time for domestic flights is 1
                      to 1 1/2 hours; 2 hours for international flights. A
                      Picture I.D. is required for all domestic flights.
                      Passports and visas (when required) are mandatory for all
                      international flights.
                    </p>
                    <p>
                      RECONFIRMATION. Domestic flights should be reconfirmed in
                      cases of inclement weather situations or if a flight was
                      booked some length of time prior to departure and
                      international flights at least 72 hours prior to
                      departure. . Failure to use any reservations may result in
                      automatic cancellation of all continuing and return
                      reservations.
                    </p>
                    <p>
                      EXCURSION AND PROMOTIONAL FARES. Most discount fares
                      involve restrictions. A change in carrier(s), flight(s),
                      time(s), or routing(s) could result in a carrier demanding
                      a full fare. Obtain agency or airline assistance before
                      making changes. If your plans change enroute, apply the
                      value of your tickets toward a re-issued one. It is not
                      necessary to purchase a new ticket. If a refund is due,
                      obtain a receipt from the airline. Departure times,
                      flights and fares are based on current tariffs that are
                      subject to change without notice.
                    </p>
                    <p>
                      TICKETS. Canceled or unused tickets (paper) must be
                      returned for proper credit to your account (airline rules
                      and restrictions will apply). Unused E-tickets should be
                      reported to BuyMyTrip to be logged and used toward future
                      travel. Lost, stolen or destroyed tickets (paper) must be
                      paid for until refund is received from the issuing
                      carrier, subject to an airline imposed service charge
                    </p>
                    <p>
                      HOTELS are usually confirmed on a guaranteed payment
                      basis. If you cancel or change plans, please notify your
                      hotel(s) within the time period specified. No refunds will
                      be issued for late check-in or early check-out. Stay
                      extensions require a new reservation. Resort fee is
                      applicable subject to hotels discretion. The price shown
                      DOES NOT include any applicable hotel service fees,
                      charges for optional incidentals (such as minibar, snacks,
                      resort fee, parking fee, valet fee or telephone calls), or
                      regulatory surcharges. The lodging will assess these fees,
                      charges, and surcharges upon check-out. This hotel
                      requires that you are at least 21 to check in. Base rates
                      are displayed as per the number of occupancy mentioned
                      during search. Unless specified otherwise, rates are
                      quoted in US dollars. In case of late arrival, you need to
                      notify the hotel prior to your check in time. Your credit
                      card will be charged for the complete payment at the time
                      of booking. Prices and room availability are not
                      guaranteed until full payment is received. In case of
                      credit card verification failure the reservation will be
                      cancelled without any prior notification
                    </p>
                  </div>
                </v-card>
              </div>
              <div class="d-flex justify-center mt-5">
                <v-btn
                  style="
                    color: green;
                    outline: 1px solid;
                    width: 200px;
                    height: 45px;
                    border-radius: 4px;
                  "
                  class="header-btn"
                  prepend-icon="mdi-printer"
                  @click="seleceprint()"
                  >Print</v-btn
                >
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
  <v-dialog v-model="dialog1">
    <div>
      <div class="trip1">
        <v-card
          style="padding: 50px; margin-top: 50px; overflow: unset"
          width="600px"
          rounded="3"
        >
          <div class="d-flex justify-end">
            <v-icon @click="dialog1 = false" size="25" color="red">
              mdi-close-circle-outline
            </v-icon>
          </div>
          <div class="d-flex justify-center">
            <h4>Modify your booking</h4>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <span> Booking Reference </span>
              <br />
              <span style="font-size: 25px; font-weight: 600">{{
                tripId
              }}</span>
            </div>

            <div class="col-md-6">
              <v-select
                variant="outlined"
                v-model="bookstatus"
                :items="[
                  'Cancel Booking',
                  'Date Change',
                  'Upgrade Booking',
                  'Request Addons',
                ]"
              ></v-select>
            </div>
          </div>

          <div v-if="datechange">
            <div class="row">
              <div class="col-md-4 d-flex justify-center">
                <h6>Outbound Between</h6>
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate1"
                  :min-date="new Date()"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate2"
                  :min-date="new Date()"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-4 d-flex justify-center">
                <h6>Inbound Between</h6>
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate3"
                  :min-date="new Date()"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate4"
                  :min-date="new Date()"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
            </div>
          </div>

          <div class="d-flex justify-center" v-if="requstup">
            <p>
              Please mention your request below.Our support team will be in
              touch with you at the soonest posible
            </p>
          </div>

          <div class="mt-2 ms-4">
            <div>Notes</div>
            <div class="mt-1 col-11">
              <v-textarea variant="outlined"></v-textarea>
            </div>
          </div>

          <div class="d-flex justify-center mt-4">
            <v-btn
              width="250px"
              color="#ff6b0a"
              @click="modifyreq(bookstatus)"
              style="height: 40px !important"
              >Request</v-btn
            >
          </div>
        </v-card>
      </div>

      <!-- <div class="d-flex justify-center " style="    flex-flow: wrap;">
 <p>
     We have received your request.Our customer support team will get back to you at the soonest possible
 </p>
 
 <p>
     Please note that  all notification requests are subject to the airline team conditions,
     and may have applicable charges
 </p>
 </div> -->
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2">
    <v-card class="w-50 m-auto py-4">
      <div class="d-flex justify-center flex-wrap">
        <h2>Your modification request has been recorded.</h2>
        <h5>Our customer support executive will get in touch with you soon.</h5>
      </div>

      <v-btn
        width="160px"
        color="#ff6b0a"
        class="m-auto mt-4"
        @click="this.dialog2 = false"
        >OK</v-btn
      >
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog3">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>
          Your request cannot be processed at this moment. 
   
        </h5>
        <h6>Please contact customer support.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog3=false"  color="#ff6b0a">Try Again</v-btn>

          <v-btn to="/" color="#ff6b0a">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <br /><br />

</div>

<!-- <div v-if="overlay">
    <dataloader></dataloader>
  </div> -->

  <div v-if="pageloader">
    <pageLoader></pageLoader>
  </div>



</template>

<script>
import moment from "moment";
import axios from "axios";
// import dataloader from "@/components/dataLoder.vue";
import airLogo from "@/airlinesLogo.json";
import userData from "@/airport_icao.json";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import pageLoader from "@/components/pageLoader.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
  components: {
    // dataloader,
    VueDatePicker,
    pageLoader,
  },
  data() {
    return {
      myBookLoad: false, 
      bookid: "",
      bookname:"",
      lastname: "",
      retreivebooking: {
        request_type: "retreive_booking",
        booking_id: "1234qwert",
        pnr: "asddf12",
        last_name: "ldkdf",
        portal_id: "port5678",
      },
      dialog: false,
      valid: false,
      pageloader: true,
      dateFormat: "dd MMM yyyy",
      overlay: false,
      mybooking: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      bookstatus: "Cancel Booking",
      requstup: false,
      datechange: false,
      dedate1: "",
      dedate2: "",
      dedate3: "",
      dedate4: "",
      retriveData: [],
      itineryData: [],
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      contactnum: "",
      passEmail: "",
      bookingStatus: "",
      bookingTime: "",
      searchcabintype: "",
      countrycode: "",


      modifyBooking:""
    };
  },
  methods: {
    capitalizeLastName() {
      this.lastname =
        this.lastname.charAt(0).toUpperCase() + this.lastname.slice(1);
    },
    circle() {
      console.log(this.$route.query.Booking_id, "this.$route.query.Booking_id");
      if (this.$route.query.Booking_id) {
        this.$router.push("/dashboard");
      } else {
        this.dialog = false;
      }
    },
    modify() {
      this.dialog1 = true;
    },
    getdob($event) {
      return moment($event).format("ddd, DD MMM'YY");
    },
    getdepdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode(data) {
      console.log(data, "pppp");
      return data[0].depart_airport;
    },
    getarrcode(data) {
      console.log(data, "pppp");
      return data[data.length - 1].arrival_airport;
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getdepdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode1(data) {
      return data[0].depart_airport;
    },
    getarrcode1(data) {
      return data[data.length - 1].arrival_airport;
    },

    getstarttime1(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    seleceprint() {
      const printContent = document.getElementById("printMe");
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    },

    bookinglist() {
      this.myBookLoad = true;
      if (this.valid) {
        this.overlay = true;
        // this.retreivebooking.booking_id = this.bookid;
        // this.retreivebooking.last_name = this.lastname;
        let obj = {
              booking_Id:this.bookid,
              last_name:this.lastname,
         
            }
            
            let booking_details = JSON.stringify(obj)


        setTimeout(() => {
          this.dialog3 = true;
          this.overlay = false;
            this.$router.push({ path: "/bookingView", query: { booking_id: booking_details }, })
              // this.$router.push({ path: "/search", query: rountdetail });
            this.myBookLoad = false;
        },2000)
      }
      else{
        this.myBookLoad = false;
        return this.valid;
      }

    },
    bookingretrive() {
      this.airlogodata = airLogo;
      this.arrivalData = [];
      this.depatureData = [];
      this.retriveData.forEach((v) => {
        console.log(v, "dtaswww");
        this.baseprice = v.fare_info.price_per_person
        this.currency = v.fare_info.itin_currency;
        this.totalprice = v.fare_info.itin_total_fare;
        this.passengerDetail = v.pax_info;
        this.itineryData = v.itinerary;
        this.contactnum = v.contact_info.paxphone;
        this.passEmail = v.contact_info.paxemail;
        this.tripId = v.booking_id;
        this.bookingStatus = v.status;
        this.bookingTime = moment(v.booking_at).format("YYYY-MM-DD HH:MM");
        this.searchcabintype = v.search_cabin_type;
        this.countrycode = v.contact_info.country_code;

        for (let i = 0; i < this.airlogodata.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.airlogodata[i].id == a.carrier) {
              a.logo = this.airlogodata[i].logo;
              a.flightname = this.airlogodata[i].name;
            }
          });
        }

        console.log(this.datareurn, "this.datareurnthis.datareurn");

        Object.keys(userData).forEach((icao) => {
          const airport = userData[icao];
          if (airport.iata !== "") {
            this.fromAirports.push({
              iata: airport.iata,
              icao: airport.icao,
              places: `${airport.name}`,
            });
          }
        });

        for (let i = 0; i < this.fromAirports.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.fromAirports[i].iata == a.depart_airport) {
              a.depAirportName = this.fromAirports[i].places;
            }
            if (this.fromAirports[i].iata == a.arrival_airport) {
              a.arrAirportName = this.fromAirports[i].places;
            }
          });
        }
        this.itineryData.forEach((j) => {
          if (j.flight_line == 2) {
            this.triptype = "ROUND TRIP";
          } else {
            this.triptype = "ONEWAY TRIP";
          }
        });

        this.itineryData.forEach((s) => {
          if (s.flight_line == 2) {
            this.datareurn = true;
            this.arrivalData.push(s);
          } else if (s.flight_line == 1) {
            this.depatureData.push(s);
          }
        });
        console.log(this.depatureData, "this.depatureData");
        console.log(this.arrivalData, "this.depatureData");

      });
      this.gettimedep()
    },

    gettimedep() {
    // alert('pppppp');
    let result = [];
    let result1 = [];
    if(this.depatureData.length>0){

    

    for (let i = 0; i < this.depatureData.length - 1; i++) {
        console.log(this.depatureData[i].depart_datetime, 'popppopoppopop');

        const time1 = new Date(this.depatureData[i + 1].depart_datetime);
        const time2 = new Date(this.depatureData[i].arrival_datetime);

        const diffInMilliseconds = Math.abs(time1 - time2);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        result.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);
    }
    
    this.depatureData.forEach((v,j)=>{
      v.overlayTime=result[j]
    })
  }


  if(this.arrivalData.length>0){

    

for (let i = 0; i < this.arrivalData.length - 1; i++) {
    console.log(this.arrivalData[i].depart_datetime, 'popppopoppopop');

    const time1 = new Date(this.arrivalData[i + 1].depart_datetime);
    const time2 = new Date(this.arrivalData[i].arrival_datetime);

    const diffInMilliseconds = Math.abs(time1 - time2);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    result1.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);
}

this.arrivalData.forEach((v,j)=>{
  v.overlayTime=result1[j]
})
}


    console.log( this.depatureData, 'ayhaiahjhashuh');
},






    modifyreq(data) {
      console.log(data, "ppppp");
      this.overlay = true;
      let result = "";
      if (data == "Cancel Booking") {
        result = "cancel";
      } else if (data == "Date Change") {
        result = "date_change";
      } else if (data == "Upgrade Booking") {
        result = "upgrade";
      } else if (data == "Request Addons") {
        result = "addon";
      }

      let modify = {
        request_type: "modify_request",
        booking_id: this.bookid,
        modify_request_type: result,
        outbound_date_between: "",
        inbound_date_between: "",
        notes: "test",
      };

      modify.outbound_date_between = `${moment(this.dedate1).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate2).format("YYYY-MM-DD")}`;
      modify.inbound_date_between = `${moment(this.dedate3).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate4).format("YYYY-MM-DD")}`;

      console.log(modify, "modifymodify");

      axios
        .post(this.modifyBooking, modify, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.modify_response == "success") {
            this.overlay = false;
            this.dialog1 = false;
            this.dialog2 = true;
          }

          console.log(response.data.modify_response, "success");
        })
        .catch((error) => {
          console.log(error, "modify");
        });
    },



    getConfig() {

// console.log(this.$store.state.data,'homehoemehojoke...')

// let getConfigData = this.$store.state.data
const getConfigData1 = getConfigDataFromLocalStorage();

if (getConfigData1) {

  this.modifyBooking = getConfigData1.payload.portal_configuration.API_endpoints.modify_booking
  console.log(this.airLogoApi, 'head...home..1..')

  // this.getConfigFunc();
}

},
  },
  watch: {
    bookstatus(newVal) {
      console.log(newVal, "newVal");
      if (newVal == "Cancel Booking") {
        this.datechange = false;
        this.requstup = false;
      } else if (newVal == "Date Change") {
        this.datechange = true;
        this.requstup = false;
      } else if (newVal == "Upgrade Booking" || newVal == "Request Addons") {
        this.datechange = false;
        this.requstup = true;
      }
    },
  },

  mounted() {
    // this.fetchData();
    document.getElementById("navbar").style.color = "black";
  },

  created() {

    this.getConfig();

    setTimeout(()=>{
      this.pageloader = false;
    },2000)

    console.log(this.$route.query.Booking_id, "pppppp");
    this.bookid = this.$route.query.Booking_id;
      this.lastname= this.$route.query.last_name;
    if (this.bookid) {
      this.valid = true;
      this.bookinglist();
    }
  },
};
</script>

<style scoped>
.trip {
  justify-content: center;
  display: flex;
  background-color: #f3d8cf;
  padding: 30px;
  font-size: 25px;
  font-weight: 600;
}
.pre-card {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  border-radius: 15px;
}

.mybookCard{
  width: 550px; 
  padding: 40px; 
  height: 280px;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.f-size-16 {
  font-size: 16px;
}

.fw-600 {
  font-weight: 600;
}

.nav-list {
  cursor: pointer;
}

.dropdown-list {
  cursor: pointer;
}

.data-list {
  cursor: pointer;
}

/* .dropdown-list {
   transition: all 0.5s ease-in-out; 
   max-height: 0; 
   overflow: hidden;
   opacity: 0;
 } */

.nav-list:hover .dropdown-list {
  max-height: 500px;
  opacity: 1;
  transition-delay: 0.5s;
}
.book-back {
  background-color: #ff5400;
}
.f-size-16 {
  font-size: 16px;
}
>>> .dp__pointer {
  cursor: pointer;
  font-size: 13px;
}
.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 13px;
    font-weight: 400;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}

/* .booking-text p{
   font-size: 17px;
   font-weight: 400;
 } */

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.f-family {
  font-family: sans-serif;
  font-size: 15px;
}

.BuyMyTrip1 {
  display: none;
}
.trip1 {
  justify-content: center;
  display: flex;
  padding: 15px;
}
>>> .v-textarea .v-field--no-label textarea,
.v-textarea .v-field--active textarea {
  opacity: 1;
  height: 50px;
}
/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 480px) {
  .print-btn-2 {
    display: none;
  }

  .mobile-table {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .contion {
    display: block;
  }

  .BuyMyTrip {
    display: none;
  }

  .BuyMyTrip1 {
    display: block;
  }

  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }

  .print-btn-1 {
    display: none;
  }

  .print-btn-2 {
    display: flex;
  }

  .f-family {
    font-size: 12px;
  }

  .airlogo-size {
    width: 25px !important;
  }

  .airline-name {
    font-size: 10px;
  }

  .f-size-16 {
    font-size: 16px;
  }

  .TERMS {
    display: none;
  }

  .f-size-14 {
    font-size: 14px;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 600;
  }
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
  color: green;
  outline: 1px solid;
  width: 130px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}







.pre-card .btn-search {
  background-color: #002d5b;
  min-height: 56px;
  color: #ffffff;
  min-width: 75px;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.pre-card .btn-search:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: -100%;
  background: #ffc104;
  transition: all 0.2s;
}
.pre-card .btn-search:hover {
  color: #343534;
}
.pre-card .btn-search:hover:before {
  top: 0;
  left: 0;
}










>>>.myBookingCard .v-input__control {
  height: 45px;
}

>>>.myBookingCard .v-field__field {
  height: 45px;
}

>>>.myBookingCard .v-field--focused {
  color: #002d5b;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
  border: none !important;
}
>>>.myBookingCard .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
}

>>>.myBookingCard .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  color: black;
  padding-top: 8px;
}


>>>.myBookingCard .v-field__input input::placeholder {
    position: relative;
    top: -10px;
}

>>>.myBookingCard .v-autocomplete .v-field__input input::placeholder {
    position: relative;
    top: 0px;
    
}
</style>

