import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.js';
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import AOS from 'aos';
import 'aos/dist/aos.css'; 



loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(AutoComplete)
   .use( AOS.init())

  .mount("#app");
  
