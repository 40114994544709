import CryptoJS from 'crypto-js';

export const getConfigDataFromLocalStorage = () => {
  const encryptedData = localStorage.getItem('portalData');
  if (encryptedData) {
    const secretKey = 'portal_config_datas';
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  }
  else{
    console.log('Something went Wrong. Try Again...');
    // alert("Something went Wrong. Try Again...");
    // window.location.href = '/';
  }

  // return null;
};
