<template>
  <v-container
    style="max-width: 1000px"
    :class="showConfirmPage ? 'visible' : 'invisible'"
  >
    <!-- <v-container> -->
    <div v-if="datavkeer">
      <div>
        <div class="d-flex justify-end">
          <a
            href="/"
            color="#ff6b0a"
            class="mx-2 f-size-14"
            style="cursor: pointer"
          >
            Book Another Flight
          </a>
          <span
            class="f-size-14 text-decoration-underline"
            @click="managePopup = !managePopup"
            style="color: #0d6efd; cursor: pointer"
          >
            Modify <span class="f-size-10"> / </span> Cancel
          </span>
        </div>
        <v-card class="mt-3 p-2">
          <div>
            <div class="row d-flex justify-content-between">
              <div class="col-lg-7 col-sm-7 col-7">
                <span class="Booking-id-head"
                  >{{ portal_sitetitle }} Booking ID:</span
                >
                <div
                  class="d-flex flex-md-row flex-column align-items-md-center align-start mb-2"
                >
                  <span class="Booking-id" style="text-transform: uppercase">{{
                    tripId
                  }}</span>
                  <span class="d-flex align-items-center">
                    <v-icon
                      class="ms-md-4 ms-0 me-sm-2 me-1"
                      size="12px"
                      :color="getColor()"
                      >mdi-circle</v-icon
                    >
                    <span class="Booking-status">{{ bookingStatus }}</span>
                  </span>
                </div>
              </div>

              <div class="col-lg-3 col-sm-3 col-3">
                <div class="d-flex justify-end hide-btn-desk">
                  <!-- <div class="d-flex justify-end">
                    <a href="/" color="#ff6b0a" class="mx-2 f-size-14" style="cursor: pointer">
                      Book Another Flight
                    </a>
                    <span class="f-size-14 text-decoration-underline" @click="managePopup = !managePopup"
                      style="color:#0D6EFD;cursor:pointer;">
                      Modify <span style="font-size: 12px"> / </span> Cancel
                    </span>
                  </div> -->
                  <!-- testing -->

                  <div class="d-flex justify-end">
                    <v-btn
                      class="print-btn-1 ms-3 me-2"
                      id="buttontActive"
                      prepend-icon="mdi-printer"
                      @click="seleceprint1()"
                      >Print</v-btn
                    >
                    <v-btn
                      class="print-btn-mbl print-btn-2 mx-2"
                      @click="seleceprint2()"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row mt-1 justify-content-between"
              v-if="getBookingStatus(bookingStatus)"
            >
              <div class="col-md-8">
                <p class="f-size-20 margin-less">
                  Your Booking Has Been
                  <span style="text-transform: capitalize"
                    >{{ bookingStatus }}.</span
                  >
                </p>
                <p v-if="bookingStatus == 'hold' ? true : false">
                  Please Contact Your customer support.
                </p>

                <p class="f-size-14 margin-less">
                  Thank you for choosing our service! Your booking has been
                  received and the reservation is processing.<br />
                  Soon your tickets will be issued and sent to your email.
                </p>
              </div>

              <!-- <div class="col-md-4 d-flex flex-md-column flex-row justify-md-start justify-end">
                <div class="mt-sm-2 mt-0 d-flex justify-end">
                  <v-btn @click="modify('dateChange')" width="100px" color="#ff6b0a" class="mx-2">
                    <span style="font-size:14px !important; font-weight: 500;">
                      Modify
                    </span>
                  </v-btn>
                </div>
                <div class="mt-sm-2 mt-0 d-flex justify-end">
                  <v-btn @click="modify('cancel')" width="100px" color="#ff6b0a" class="mx-2">
                    <span style="font-size:14px !important;font-weight: 500;">
                      Cancel
                    </span>
                  </v-btn>
                </div>
              </div> -->
            </div>
          </div>
        </v-card>
      </div>
      <div id="printMe">
        <div class="mt-3">
          <div class="px-2 my-2">
            <v-img
              :src="portalLogo"
              style="max-width: 140px; cursor: pointer"
            />
          </div>
          <v-card class="p-2">
            <div
              v-if="rount || oneway"
              class="p-3 bookingInfoCard"
              style="border: 1px solid grey; border-radius: 5px"
            >
              <div class="row align-center">
                <div
                  class="col-md-2 col-sm-1 col-2 my-md-0 my-2 bookTripId-info"
                  style="width: 20%"
                >
                  <p class="m-0 f-size-13 fw-500">Trip ID #</p>
                  <span class="f-size-12">{{ tripId }}</span>
                </div>

                <div
                  class="col-md-5 col-sm-6 col-4 justify-center my-md-0 my-2 bookPlace-info"
                  style="width: 35%"
                >
                  <div class="row">
                    <div class="col-6">
                      <span class="f-size-13 fw-500">{{
                        getdepcode(depatureData)
                      }}</span>
                      <br />
                      <span class="f-size-12">{{
                        getstarttime(depatureData)
                      }}</span>
                    </div>
                    <div class="col-6">
                      <span class="f-size-13 fw-500">{{
                        getarrcode(depatureData)
                      }}</span>
                      <br />
                      <span class="f-size-12">{{
                        rount
                          ? getstarttime1(arrivalData)
                          : getOnewaystarttime1(depatureData)
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-3 col-sm-3 col-3 my-md-0 my-2 bookDate-info"
                  style="width: 23%"
                >
                  <p class="m-0 f-size-13 fw-500">Booking Date</p>
                  <span class="f-size-12">{{ bookingTime }}</span>
                </div>

                <div
                  class="col-md-2 col-sm-2 col-5 justify-center my-md-0 my-2 bookTrip-info"
                  style="width: 22%"
                >
                  <div>
                    <!-- <span style="margin-right: 10px">&#9679;</span> -->
                    <p class="m-0 f-size-13 fw-500">&#9679; {{ triptype }}</p>
                    <p
                      class="m-0 f-size-13 fw-500"
                      v-if="searchcabintype"
                      style="
                        text-transform: uppercase;
                        position: relative;
                        top: 1;
                      "
                    >
                      &#9679; {{ searchcabintype }}
                    </p>
                  </div>

                  <!-- <div class="d-flex align-center" v-if="searchcabintype">
                    <span style="margin-right: 10px">&#9679;</span>
                    
                  </div> -->
                </div>
              </div>
            </div>

            <div
              v-if="multi"
              style="border: 1px solid grey; border-radius: 5px; padding: 5px"
            >
              <div class="row align-center">
                <div
                  class="col-md-2 col-sm-1 col-2 my-md-0 my-2"
                  style="width: 20%"
                >
                  <p class="m-0 f-size-13 fw-500">Trip ID #</p>
                  <span class="f-size-12">{{ tripId }}</span>
                </div>

                <div
                  class="col-md-5 col-sm-6 col-4 justify-center my-md-0 my-2"
                  style="width: 35%"
                >
                  <div class="row" v-if="Flight1.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight1) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight1) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight1) }}
                      </span>
                    </div>
                  </div>

                  <div class="row" v-if="Flight2.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight2) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight2) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight2) }}
                      </span>
                    </div>
                  </div>

                  <div class="row" v-if="Flight3.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight3) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight3) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight3) }}
                      </span>
                    </div>
                  </div>

                  <div class="row" v-if="Flight4.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight4) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight4) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight4) }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-3 col-sm-3 col-3 my-md-0 my-2"
                  style="width: 23%"
                >
                  <p class="m-0 f-size-13 fw-500">Booking Date</p>
                  <span class="f-size-12">{{ bookingTime }}</span>
                </div>

                <div
                  class="col-md-2 col-sm-2 col-5 justify-center my-md-0 my-2"
                  style="width: 22%"
                >
                  <div>
                    <!-- <span style="margin-right: 10px">&#9679;</span> -->
                    <p class="m-0 f-size-13 fw-500">&#9679; {{ triptype }}</p>
                    <p
                      class="m-0 f-size-13 fw-500"
                      v-if="searchcabintype"
                      style="
                        text-transform: uppercase;
                        position: relative;
                        top: 1;
                      "
                    >
                      &#9679; {{ searchcabintype }}
                    </p>
                  </div>
                  <!-- <div class="d-flex align-center" v-if="searchcabintype">
                    <span style="margin-right: 10px">&#9679;</span>
                    
                  </div> -->
                </div>
              </div>
            </div>
          </v-card>

          <v-card class="p-2 mt-3">
            <div class="col-md-12">
              <div class="text-dark f-size-12 text-end">E-ticket number:</div>
            </div>
            <div
              class="row"
              v-for="(datas1, index) of passengerDetail"
              :key="index"
            >
              <div class="col-md-8 mb-1">
                <div class="row">
                  <div class="col-md-5 col-7 pass-info-1">
                    <div class="text-dark" style="text-transform: uppercase">
                      <span class="ms-4 pass-icon">
                        <v-icon>mdi-account</v-icon>
                      </span>
                      <span class="ms-4 f-size-12 fw-500 pass-name">
                        {{ datas1.firstname }} {{ datas1.middlename }}
                        {{ datas1.lastname }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-5 col-5 pass-info-2">
                    <div
                      class="text-dark f-size-12 fw-500 mt-1"
                      style="text-transform: uppercase"
                    >
                      {{ datas1.gender }} / {{ datas1.pax_type }}
                    </div>
                    <!-- <div class="text-dark f-size-12 mt-1" style="text-transform: uppercase">
                      {{ datas1.gender }} / {{ getdob(datas1.dob) }} /
                      {{ datas1.pax_type }}
                    </div> -->
                    <!-- testing -->
                  </div>
                </div>
              </div>

              <div class="col-md-4 mb-1">
                <div class="text-dark f-size-12 text-end" v-if="Eticketno">
                  {{ datas1.eticket_no }}
                </div>
              </div>
            </div>
            <hr style="margin: 0" />
            <div class="text-end text-dark f-size-12 mt-2">
              The e-ticket number is valid for all flights
            </div>

            <!-- <div>
              <div>
                <div class="row mt-2">
                  <h5 class="col-12 col-md-6">
                    <v-icon color="grey">mdi-script-text</v-icon>
                    PASSENGER DETAILS
                  </h5>
                </div>

                <v-table class="text-center rounded desktop-table" style="border: 1px solid lightgray">
                  <thead>
                    <tr>
                      <th class="text-center text-dark f-size-13">
                        PASSENGER NAME
                      </th>
                      <th class="text-center text-dark f-size-13">GENDER</th>
                      <th class="text-center text-dark f-size-13">DOB</th>
                      <th class="text-center text-dark f-size-13">PAX TYPE</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(datas1, index) of passengerDetail" :key="index">
                      <td class="text-center text-dark f-size-12 f-size-12" style="text-transform: uppercase"class="text-center text-dark f-size-12 f-size-12" style="text-transform: uppercase">
                        <span style="text-transform: capitalize">
                          {{ datas1.salutation }}</span>
                        {{ datas1.firstname }}
                        {{ datas1.middlename }} {{ datas1.lastname }}
                      </td>
                      <td class="text-center text-dark f-size-12" style="text-transform: uppercase">
                        {{ datas1.gender }}
                      </td>
                      <td class="text-center text-dark f-size-12">
                        {{ getdob(datas1.dob) }}
                      </td>
                      <td class="text-center text-dark f-size-12" style="text-transform: uppercase">
                        {{ datas1.pax_type }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>

                <div class="mt-3" style="margin-left: 35px">
                  <h5 style="font-size: 18px">Contact Info</h5>
                  <div class="row mt-3">
                    <div class="col-sm-6 col-12">
                      <span class="f-size-13 fw-600">Phone : </span>
                      <span class="f-size-12">{{ countrycode }} {{ contactnum }}</span>
                    </div>
                    <div class="col-sm-6 col-12">
                      <span class="f-size-13 fw-600">Email : </span>
                      <span class="f-size-12"> {{ passEmail }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </v-card>

          <v-card class="p-2 mt-2">
            <div>
              <div>
                <!-- <div class="row mt-2">
                  <h5 class="col-12 col-md-6">
                    <v-icon color="grey">mdi-ticket</v-icon>
                    ITINERARY DETAILS
                  </h5>
                </div> -->

                <!-- <v-table class="text-center rounded desktop-table" style="border: 1px solid lightgray">
                  <thead>
                    <tr>
                      <th class="text-center text-dark f-size-13">PASSENGER NAME</th>
                      <th class="text-center text-dark f-size-13">GENDER</th>
                      <th class="text-center text-dark f-size-13">DOB</th>
                      <th class="text-center text-dark f-size-13">PAX TYPE</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(datas1, index) of passengerDetail" :key="index">
                      <td class="text-center text-dark f-size-12 f-size-12" style="text-transform: uppercase">
                        <span style="text-transform: capitalize">
                          {{ datas1.salutation }}</span>
                        {{ datas1.firstname }}
                        {{ datas1.middlename }} {{ datas1.lastname }}
                      </td>
                      <td class="text-center text-dark f-size-12" style="text-transform: uppercase">
                        {{ datas1.gender }}
                      </td>
                      <td class="text-center text-dark f-size-12">
                        {{ getdob(datas1.dob) }}
                      </td>
                      <td class="text-center text-dark f-size-12" style="text-transform: uppercase">
                        {{ datas1.pax_type }}
                      </td>
                    </tr>
                  </tbody>
                </v-table> -->
              </div>
            </div>

            <!-- <div class="mt-3" style="margin-left: 35px">
              <h5 style="font-size: 18px">Contact Info</h5>
              <div class="row mt-3">
                <div class="col-sm-6 col-12">
                  <span class="f-size-13 fw-600">Phone : </span>
                  <span class="f-size-12">{{ countrycode }} {{ contactnum }}</span>
                </div>
                <div class="col-sm-6 col-12">
                  <span class="f-size-13 fw-600">Email : </span>
                  <span class="f-size-12"> {{ passEmail }}</span>
                </div>
              </div>
            </div> -->

            <!-- ---------flight sements---------- -->
            <div v-if="depatureData.length > 0">
              <div
                class="mt-2"
                style="border: 1px solid lightgray; border-radius: 5px"
              >
                <div
                  style="
                    background-color: #254976;
                    color: white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="d-flex">
                        <div>
                          <v-icon
                            class="iconSize"
                            color="white"
                            style="transform: rotate(90deg) !important"
                          >
                            mdi-airplane
                          </v-icon>
                        </div>
                        <div class="ms-2 ItinHead">
                          Depature | {{ depatureData[0].depart_airport }} -
                          {{
                            depatureData[depatureData.length - 1]
                              .arrival_airport
                          }}
                          ({{ getstarttime1(depatureData) }})
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="ItinHead">
                        Stops: {{ depatureData.length - 1 }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of depatureData" :key="index">
                  <div class="row" style="border-bottom: 1px solid lightgray">
                    <div
                      class="col-md-8 col-8"
                      style="border-right: 1px solid lightgray"
                    >
                      <div class="row p-2">
                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Depature</h6>

                          <div>
                            <span class="f-size-13 fw-600">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                            <span class="f-size-11 fw-600 ms-1">
                              {{ getdepdate1(data.depart_datetime) }}</span
                            >
                          </div>
                          <div class="f-size-13 fw-600">
                            {{ data.departure_city_name }}
                          </div>
                          <div class="f-size-12 fw-400">
                            ({{ data.depart_airport }})
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="col-12 col-md-6 arrMargin">
                          <h6 style="color: gray">Arrival</h6>

                          <div class="f-size-13 fw-500">
                            <span class="f-size-13 fw-600">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                            <span class="f-size-11 fw-600 ms-1">
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                          </div>
                          <div class="f-size-13 fw-600">
                            {{ data.arrival_city_name }}
                          </div>
                          <div class="f-size-12 fw-400">
                            ({{ data.arrival_airport }})
                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="border-bottom: 2px dashed #a7a5a5"
                        v-if="depatureData.length - 1 == index ? false : true"
                      ></div>
                      <div
                        class="d-flex justify-start p-1 f-size-13 fw-500"
                        v-if="depatureData.length - 1 == index ? false : true"
                      >
                        <v-icon color="black">mdi-clock</v-icon>
                        <span style="margin-left: 2px">
                          {{ data.overlayTime }} Layover Time</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4 col-4 p-2">
                      <div class="view_tap">
                        <div style="font-size: 14px" class="mb-2">
                          <div class="d-flex">
                            <span>
                              <v-img
                                :src="data.logo"
                                class="airlogo-size"
                                width="25px"
                              ></v-img>
                            </span>
                            <span class="f-size-15 fw-500 ms-1">{{
                              data.flightname
                            }}</span>
                          </div>
                        </div>
                        <div class="f-size-12 fw-400">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div class="f-size-12 fw-400">
                          Flight number:
                          <span style="font-weight: 500">
                            {{ data.carrier }} - {{ data.flight_number }}
                          </span>
                        </div>
                        <div class="f-size-12 fw-400" v-if="searchcabintype">
                          Class: {{ this.searchcabintype }}
                        </div>
                        <div class="f-size-12 fw-400" v-if="Eticketno">
                          Airlines PNR: {{ data.airline_pnr }}
                        </div>
                      </div>

                      <div class="view_mobile">
                        <div class="fw-500" style="font-size: 14px">
                          <div>
                            <v-img
                              :src="data.logo"
                              class="airlogo-size"
                              width="30px"
                            ></v-img>
                          </div>
                          <div>
                            <span>{{ data.flightname }}</span>
                          </div>
                        </div>
                        <div class="f-size-12 fw-400">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div class="f-size-13 fw-400">
                          {{ data.carrier }} - {{ data.flight_number }}
                        </div>
                        <div class="f-size-13 fw-400" v-if="searchcabintype">
                          {{ this.searchcabintype }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="arrivalData.length > 0">
              <div
                style="
                  border: 1px solid lightgray;
                  border-radius: 5px;
                  margin-top: 20px;
                "
              >
                <div
                  style="
                    background-color: #254976;
                    color: white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="d-flex">
                        <div>
                          <v-icon
                            color="white"
                            class="iconSize"
                            style="transform: rotate(90deg) !important"
                          >
                            mdi-airplane
                          </v-icon>
                        </div>

                        <div
                          class="ms-2 ItinHead"
                          v-if="depatureData.length > 0"
                        >
                          Return |
                          {{
                            depatureData[depatureData.length - 1]
                              .arrival_airport
                          }}
                          - {{ depatureData[0].depart_airport }} ({{
                            getstarttime1(arrivalData)
                          }})
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="ItinHead">
                        Stops: {{ arrivalData.length - 1 }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of arrivalData" :key="index">
                  <div class="row" style="border-bottom: 1px solid lightgray">
                    <div
                      class="col-md-8 col-8"
                      style="border-right: 1px solid lightgray"
                    >
                      <div class="row p-2">
                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Depature</h6>

                          <div>
                            <span class="f-size-13 fw-600">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                            <span class="f-size-11 fw-600 ms-1">
                              {{ getdepdate1(data.depart_datetime) }}</span
                            >
                          </div>
                          <div class="f-size-13 fw-600">
                            {{ data.departure_city_name }}
                          </div>
                          <div class="f-size-12 fw-400">
                            ({{ data.depart_airport }})
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="col-12 col-md-6 arrMargin">
                          <h6 style="color: gray">Arrival</h6>

                          <div style="font-size: 13px; font-weight: 500">
                            <span class="f-size-13 fw-600">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                            <span class="f-size-11 fw-600 ms-1">
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                          </div>
                          <div class="f-size-13 fw-600">
                            {{ data.arrival_city_name }}
                          </div>
                          <div class="f-size-12 fw-400">
                            ({{ data.arrival_airport }})
                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="border-bottom: 2px dashed #a7a5a5"
                        v-if="arrivalData.length - 1 == index ? false : true"
                      ></div>
                      <div
                        class="d-flex justify-start p-1"
                        style="font-size: 13px; font-weight: 500"
                        v-if="arrivalData.length - 1 == index ? false : true"
                      >
                        <v-icon color="black">mdi-clock</v-icon>
                        <span style="margin-left: 2px">
                          {{ data.overlayTime }} Layover Time</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4 col-4 p-2">
                      <div class="view_tap">
                        <div style="font-size: 14px" class="mb-2">
                          <div class="d-flex">
                            <span>
                              <v-img
                                :src="data.logo"
                                class="airlogo-size"
                                width="25px"
                              ></v-img>
                            </span>
                            <span class="f-size-15 fw-500 ms-1">{{
                              data.flightname
                            }}</span>
                          </div>
                        </div>
                        <div class="f-size-12 fw-400">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div class="f-size-12 fw-400">
                          Flight number:
                          <span style="font-weight: 500">
                            {{ data.carrier }} - {{ data.flight_number }}
                          </span>
                        </div>
                        <div class="f-size-12 fw-400" v-if="searchcabintype">
                          Class: {{ this.searchcabintype }}
                        </div>
                        <div class="f-size-12 fw-400" v-if="Eticketno">
                          Airlines PNR: {{ data.airline_pnr }}
                        </div>
                      </div>

                      <div class="view_mobile">
                        <div class="fw-500" style="font-size: 14px">
                          <div>
                            <v-img
                              :src="data.logo"
                              class="airlogo-size"
                              width="30px"
                            ></v-img>
                          </div>
                          <div>
                            <span>{{ data.flightname }}</span>
                          </div>
                        </div>
                        <div class="f-size-12 fw-400">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div class="f-size-13 fw-400">
                          {{ data.carrier }} - {{ data.flight_number }}
                        </div>
                        <div class="f-size-13 fw-400" v-if="searchcabintype">
                          {{ this.searchcabintype }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div ></div> -->
                <!-- <div>a

    daekalaa
    aekamkadklmklqek

    askaa;l
</div> -->
              </div>
            </div>

            <!-- --------------------------flight sements end----------------------- -->

            <div>
              <!-- <div>
              <v-card class="mt-2 px-2" style="box-shadow: none" v-if="depatureData.length > 0 ? true : false">
                <hr class="mb-sm-4 mb-0 mt-0" style="margin-bottom: 0px !important" />
                <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                  <div class="col-md-4 col-6">
                    <h6 class="m-0" style="font-size: 18px">
                      <v-icon color="grey" style="font-size: 24px;transform: rotate(90deg) !important;">
                        mdi-airplane
                      </v-icon>
                      <span class="ms-2"> {{ getdepcode(depatureData) }} </span>-
                      {{ getarrcode(depatureData) }}
                      <span style="font-size: 15px">

                      </span>
                    </h6>
                  </div>
                  <div class="col-md-6 col-6 d-flex align-center px-sm-3 px-0">

                    <v-icon class="me-1" color="grey">mdi-calendar</v-icon>
                    <h6 class="m-0">{{ getstarttime(depatureData) }}</h6>
                  </div>
                </div>
                <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                <v-container style="max-width: 100%">
                  <div v-for="(data, index) of depatureData" :key="index">
                    <div class="row mt-1 g-0 mb-1">
                      <div class="col-md-4 col-3 mb-1 mb-md-0">
                        <div class="row align-end w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-family airline-name m-0">
                              <span class="f-family airline-name">{{
                                data.flightname
                              }}</span>
                              <br />
                              <span class="f-family airline-name airline-id">
                                {{ data.carrier }} -
                                {{ data.flight_number }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getdepdate(data.depart_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getdeptime(data.depart_datetime) }}
                            </span>
                          </div>
                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">
                              {{ data.depart_airport }} </span>,
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getarrdate(data.arrival_datetime) }} 
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                          </div>

                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">{{
                              data.arrival_airport
                            }}</span>,

                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>


                    </div>

                    <div class="d-flex position-relative">
                      <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                      <div class="d-flex justify-center" v-if="depatureData.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                        <div class="d-flex align-center">
                          <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                        </div>
                      </div>
                    </div>



                  </div>
                </v-container>
              </v-card>
            </div> -->

              <!-- 
            <div class="mt-1" v-if="rount">
              <v-card class="mt-1 px-2" style="box-shadow: none" v-if="arrivalData.length !== 0">
                <hr class="mb-sm-4 mb-0 mt-0" style="margin-bottom: 0px !important" />
                <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                  <div class="col-md-4 col-6">
                    <h6 class="m-0" style="font-size: 18px">
                      <v-icon color="grey" style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            ">
                        mdi-airplane
                      </v-icon>
                      <span class="ms-2"> {{ getdepcode1(arrivalData) }} </span>-
                      {{ getarrcode1(arrivalData) }}
                     
                    </h6>
                  </div>
                  <div class="col-md-6 col-6 d-flex align-center px-sm-3 px-0">

                    <v-icon class="me-1" color="grey">mdi-calendar</v-icon>
                    <h6 class="m-0">{{ getstarttime1(arrivalData) }}</h6>
                  </div>
                </div>
                <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                <v-container style="max-width: 100%">
                  <div v-for="(data, index) of arrivalData" :key="index">
                    <div class="row mt-3 g-0 mb-2">
                      <div class="col-md-4 col-3 mb-3 mb-md-0">
                        <div class="row align-end w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-family airline-name m-0">
                              <span class="f-family airline-name">{{
                                data.flightname
                              }}</span>
                              <br />
                              <span class="f-family airline-name airline-id">
                                {{ data.carrier }} -
                                {{ data.flight_number }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getdepdate1(data.depart_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                          </div>
                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">
                              {{ data.depart_airport }} </span>,
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getarrdate1(data.arrival_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getarrtime1(data.arrival_datetime) }}
                            </span>
                          </div>

                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">{{
                              data.arrival_airport
                            }}</span>,

                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                            class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                          >
                            <div class="d-flex align-center class-type">
                              <v-icon size="15px" color="#ccc">
                                mdi-seat-passenger
                              </v-icon>

                              <span
                                class="f-family"
                                style="
                                  padding-left: 5px;
                                  text-transform: uppercase;
                                "
                              >
                                {{ data.cabin_type }}
                              </span>
                            </div>
                          </div>
                    </div>

                    <div class="d-flex position-relative">
                      <hr v-if="arrivalData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                      <div class="d-flex justify-center" v-if="arrivalData.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                        <div class="d-flex align-center">
                          <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-container>
              </v-card>
            </div> -->

              <div
                style="border: 1px solid lightgray; border-radius: 5px"
                v-if="Flight1.length > 0"
              >
                <div
                  style="
                    background-color: #254976;
                    color: white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row">
                    <div class="col-md-10">
                      <div class="d-flex">
                        <div>
                          <v-icon
                            color="white"
                            style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            "
                          >
                            mdi-airplane
                          </v-icon>
                        </div>
                        <div v-if="Flight1.length > 0">
                          {{ Flight1[0].depart_airport }} -
                          {{ Flight1[Flight1.length - 1].arrival_airport }}

                          ({{ getstarttime1(Flight1) }})
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="d-flex justify-center">
                        Stops:{{ Flight1.length - 1 }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of Flight1" :key="index">
                  <div class="row" style="border-bottom: 1px solid lightgray">
                    <div
                      class="col-md-8 col-8"
                      style="border-right: 1px solid lightgray"
                    >
                      <div class="row p-2">
                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Depature</h6>

                          <div>
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getdepdate1(data.depart_datetime) }}</span
                            >
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.departure_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.depart_airport }})
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Arrival</h6>

                          <div style="font-size: 13px; font-weight: 500">
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.arrival_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.arrival_airport }})
                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="border-bottom: 2px dashed #a7a5a5"
                        v-if="Flight1.length - 1 == index ? false : true"
                      ></div>
                      <div
                        class="d-flex justify-start p-1"
                        style="font-size: 13px; font-weight: 500"
                        v-if="Flight1.length - 1 == index ? false : true"
                      >
                        <v-icon color="black">mdi-clock</v-icon>
                        <span style="margin-left: 2px">
                          {{ data.overlayTime }} Layover Time</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4 col-4 p-1">
                      <div class="view_tap">
                        <div style="font-size: 14px">
                          <div class="d-flex">
                            <span>
                              <v-img
                                :src="data.logo"
                                class="airlogo-size"
                                width="25px"
                              ></v-img>
                            </span>
                            <span
                              style="
                                margin-left: 5px;
                                font-size: 15px;
                                font-weight: 500;
                              "
                              >{{ data.flightname }}</span
                            >
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 12px">
                          Flight number:
                          <span style="font-weight: 500">
                            {{ data.carrier }} - {{ data.flight_number }}
                          </span>
                        </div>
                        <div style="font-size: 12px">
                          Class: {{ this.searchcabintype }}
                        </div>
                        <div class="f-size-12 fw-400" v-if="Eticketno">
                          Airlines PNR: {{ data.airline_pnr }}
                        </div>
                      </div>

                      <div class="view_mobile">
                        <div style="font-size: 14px">
                          <div>
                            <v-img
                              :src="data.logo"
                              class="airlogo-size"
                              width="30px"
                            ></v-img>
                          </div>
                          <div>
                            <span>{{ data.flightname }}</span>
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 13px">
                          {{ data.carrier }} - {{ data.flight_number }}
                        </div>
                        <div style="font-size: 13px">
                          {{ this.searchcabintype }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div ></div> -->
                <!-- <div>a

    daekalaa
    aekamkadklmklqek

    askaa;l
</div> -->
              </div>

              <div
                style="
                  border: 1px solid lightgray;
                  border-radius: 5px;
                  margin-top: 10px;
                "
                v-if="Flight2.length > 0"
              >
                <div
                  style="
                    background-color: #254976;
                    color: white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row">
                    <div class="col-md-10">
                      <div class="d-flex">
                        <div>
                          <v-icon
                            color="white"
                            style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            "
                          >
                            mdi-airplane
                          </v-icon>
                        </div>
                        <div v-if="Flight2.length > 0">
                          {{ Flight2[0].depart_airport }} -
                          {{ Flight2[Flight2.length - 1].arrival_airport }}

                          ({{ getstarttime1(Flight2) }})
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="d-flex justify-center">
                        Stops:{{ Flight2.length - 1 }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of Flight2" :key="index">
                  <div class="row" style="border-bottom: 1px solid lightgray">
                    <div
                      class="col-md-8 col-8"
                      style="border-right: 1px solid lightgray"
                    >
                      <div class="row p-2">
                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Depature</h6>

                          <div>
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getdepdate1(data.depart_datetime) }}</span
                            >
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.departure_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.depart_airport }})
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Arrival</h6>

                          <div style="font-size: 13px; font-weight: 500">
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.arrival_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.arrival_airport }})
                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="border-bottom: 2px dashed #a7a5a5"
                        v-if="Flight2.length - 1 == index ? false : true"
                      ></div>
                      <div
                        class="d-flex justify-start p-1"
                        style="font-size: 13px; font-weight: 500"
                        v-if="Flight2.length - 1 == index ? false : true"
                      >
                        <v-icon color="black">mdi-clock</v-icon>
                        <span style="margin-left: 2px">
                          {{ data.overlayTime }} Layover Time</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4 col-4 p-1">
                      <div class="view_tap">
                        <div style="font-size: 14px">
                          <div class="d-flex">
                            <span>
                              <v-img
                                :src="data.logo"
                                class="airlogo-size"
                                width="25px"
                              ></v-img>
                            </span>
                            <span
                              style="
                                margin-left: 5px;
                                font-size: 15px;
                                font-weight: 500;
                              "
                              >{{ data.flightname }}</span
                            >
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 12px">
                          Flight number:
                          <span style="font-weight: 500">
                            {{ data.carrier }} - {{ data.flight_number }}
                          </span>
                        </div>
                        <div style="font-size: 12px">
                          Class: {{ this.searchcabintype }}
                        </div>
                        <div class="f-size-12 fw-400" v-if="Eticketno">
                          Airlines PNR: {{ data.airline_pnr }}
                        </div>
                      </div>

                      <div class="view_mobile">
                        <div style="font-size: 14px">
                          <div>
                            <v-img
                              :src="data.logo"
                              class="airlogo-size"
                              width="30px"
                            ></v-img>
                          </div>
                          <div>
                            <span>{{ data.flightname }}</span>
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 13px">
                          {{ data.carrier }} - {{ data.flight_number }}
                        </div>
                        <div style="font-size: 13px">
                          {{ this.searchcabintype }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div ></div> -->
                <!-- <div>a

    daekalaa
    aekamkadklmklqek

    askaa;l
</div> -->
              </div>

              <div
                style="
                  border: 1px solid lightgray;
                  border-radius: 5px;
                  margin-top: 10px;
                "
                v-if="Flight3.length > 0"
              >
                <div
                  style="
                    background-color: #254976;
                    color: white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row">
                    <div class="col-md-10">
                      <div class="d-flex">
                        <div>
                          <v-icon
                            color="white"
                            style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            "
                          >
                            mdi-airplane
                          </v-icon>
                        </div>
                        <div v-if="Flight3.length > 0">
                          {{ Flight3[0].depart_airport }} -
                          {{ Flight3[Flight3.length - 1].arrival_airport }}

                          ({{ getstarttime1(Flight3) }})
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="d-flex justify-center">
                        Stops:{{ Flight3.length - 1 }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of Flight3" :key="index">
                  <div class="row" style="border-bottom: 1px solid lightgray">
                    <div
                      class="col-md-8 col-8"
                      style="border-right: 1px solid lightgray"
                    >
                      <div class="row p-2">
                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Depature</h6>

                          <div>
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getdepdate1(data.depart_datetime) }}</span
                            >
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.departure_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.depart_airport }})
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Arrival</h6>

                          <div style="font-size: 13px; font-weight: 500">
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.arrival_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.arrival_airport }})
                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="border-bottom: 2px dashed #a7a5a5"
                        v-if="Flight3.length - 1 == index ? false : true"
                      ></div>
                      <div
                        class="d-flex justify-start p-1"
                        style="font-size: 13px; font-weight: 500"
                        v-if="Flight3.length - 1 == index ? false : true"
                      >
                        <v-icon color="black">mdi-clock</v-icon>
                        <span style="margin-left: 2px">
                          {{ data.overlayTime }} Layover Time</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4 col-4 p-1">
                      <div class="view_tap">
                        <div style="font-size: 14px">
                          <div class="d-flex">
                            <span>
                              <v-img
                                :src="data.logo"
                                class="airlogo-size"
                                width="25px"
                              ></v-img>
                            </span>
                            <span
                              style="
                                margin-left: 5px;
                                font-size: 15px;
                                font-weight: 500;
                              "
                              >{{ data.flightname }}</span
                            >
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 12px">
                          Flight number:
                          <span style="font-weight: 500">
                            {{ data.carrier }} - {{ data.flight_number }}
                          </span>
                        </div>
                        <div style="font-size: 12px">
                          Class: {{ this.searchcabintype }}
                        </div>
                        <div class="f-size-12 fw-400" v-if="Eticketno">
                          Airlines PNR: {{ data.airline_pnr }}
                        </div>
                      </div>

                      <div class="view_mobile">
                        <div style="font-size: 14px">
                          <div>
                            <v-img
                              :src="data.logo"
                              class="airlogo-size"
                              width="30px"
                            ></v-img>
                          </div>
                          <div>
                            <span>{{ data.flightname }}</span>
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 13px">
                          {{ data.carrier }} - {{ data.flight_number }}
                        </div>
                        <div style="font-size: 13px">
                          {{ this.searchcabintype }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div ></div> -->
                <!-- <div>a

    daekalaa
    aekamkadklmklqek

    askaa;l
</div> -->
              </div>

              <div
                style="
                  border: 1px solid lightgray;
                  border-radius: 5px;
                  margin-top: 10px;
                "
                v-if="Flight4.length > 0"
              >
                <div
                  style="
                    background-color: #254976;
                    color: white;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    padding: 5px;
                  "
                >
                  <div class="row">
                    <div class="col-md-10">
                      <div class="d-flex">
                        <div>
                          <v-icon
                            color="white"
                            style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            "
                          >
                            mdi-airplane
                          </v-icon>
                        </div>
                        <div v-if="Flight4.length > 0">
                          {{ Flight4[0].depart_airport }} -
                          {{ Flight4[Flight4.length - 1].arrival_airport }}

                          ({{ getstarttime1(Flight4) }})
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="d-flex justify-center">
                        Stops:{{ Flight4.length - 1 }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of Flight4" :key="index">
                  <div class="row" style="border-bottom: 1px solid lightgray">
                    <div
                      class="col-md-8 col-8"
                      style="border-right: 1px solid lightgray"
                    >
                      <div class="row p-2">
                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Depature</h6>

                          <div>
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getdepdate1(data.depart_datetime) }}</span
                            >
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.departure_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.depart_airport }})
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="col-12 col-md-6">
                          <h6 style="color: gray">Arrival</h6>

                          <div style="font-size: 13px; font-weight: 500">
                            <span style="font-size: 13px; font-weight: 600">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                            <span
                              style="
                                font-size: 11px;
                                font-weight: 600;
                                margin-left: 2px;
                              "
                            >
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                          </div>
                          <div style="font-size: 13px; font-weight: 600">
                            {{ data.arrival_city_name }}
                          </div>
                          <div style="font-size: 12px">
                            ({{ data.arrival_airport }})
                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>
                      <div
                        style="border-bottom: 2px dashed #a7a5a5"
                        v-if="Flight4.length - 1 == index ? false : true"
                      ></div>
                      <div
                        class="d-flex justify-start p-1"
                        style="font-size: 13px; font-weight: 500"
                        v-if="Flight4.length - 1 == index ? false : true"
                      >
                        <v-icon color="black">mdi-clock</v-icon>
                        <span style="margin-left: 2px">
                          {{ data.overlayTime }} Layover Time</span
                        >
                      </div>
                    </div>

                    <div class="col-md-4 col-4 p-1">
                      <div class="view_tap">
                        <div style="font-size: 14px">
                          <div class="d-flex">
                            <span>
                              <v-img
                                :src="data.logo"
                                class="airlogo-size"
                                width="25px"
                              ></v-img>
                            </span>
                            <span
                              style="
                                margin-left: 5px;
                                font-size: 15px;
                                font-weight: 500;
                              "
                              >{{ data.flightname }}</span
                            >
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 12px">
                          Flight number:
                          <span style="font-weight: 500">
                            {{ data.carrier }} - {{ data.flight_number }}
                          </span>
                        </div>
                        <div style="font-size: 12px">
                          Class: {{ this.searchcabintype }}
                        </div>
                        <div class="f-size-12 fw-400" v-if="Eticketno">
                          Airlines PNR: {{ data.airline_pnr }}
                        </div>
                      </div>

                      <div class="view_mobile">
                        <div style="font-size: 14px">
                          <div>
                            <v-img
                              :src="data.logo"
                              class="airlogo-size"
                              width="30px"
                            ></v-img>
                          </div>
                          <div>
                            <span>{{ data.flightname }}</span>
                          </div>
                        </div>
                        <div style="font-size: 12px">
                          Flight time: {{ flighttimeData(data) }} hour(s)
                          {{ flighttimeData1(data) }}
                          minute(s)
                        </div>
                        <div style="font-size: 13px">
                          {{ data.carrier }} - {{ data.flight_number }}
                        </div>
                        <div style="font-size: 13px">
                          {{ this.searchcabintype }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div ></div> -->
                <!-- <div>a

    daekalaa
    aekamkadklmklqek

    askaa;l
</div> -->
              </div>
            </div>
          </v-card>
        </div>

        <div class="mt-3">
          <v-card class="p-2">
            <div>
              <div>
                <!-- <div class="row mt-1">
                  <h5 class="col-12 col-md-6">
                    <v-icon color="grey">mdi-cash-multiple</v-icon>
                    FARE SUMMARY
                  </h5>
                </div> -->

                <div class="mt-1">
                  <!-- <v-card class="m-2 px-2"> -->
                  <div class="row">
                    <div class="col-sm-6 price_margin d-flex">
                      <span class="fw-bold f-family"
                        >Ticket Price per person:
                      </span>
                      <span class="ps-2 f-family">
                        {{ currency }} {{ baseprice }}</span
                      >
                    </div>

                    <div class="col-sm-6 d-flex justify-content-sm-end">
                      <h5 class="m-0">Total Price:</h5>
                      <h5 class="ms-2 m-0">{{ currency }} {{ totalprice }}</h5>
                    </div>
                  </div>
                  <!-- </v-card> -->
                </div>
              </div>
            </div>
          </v-card>
          <div class="mt-4">
            <v-card class="p-3">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <h6>TERMS & CONDITIONS</h6>
                </div>
                <div class="col-sm-6 col-md-6 contion">
                  <a href="#" style="font-size: 13px">
                    Show Tearms & Conditions</a
                  >
                </div>
              </div>

              <div
                style="
                  border: 1px solid grey;
                  padding: 10px;
                  border-radius: 10px;
                  font-size: 10px;
                "
                class="BuyMyTrip"
              >
                <p>
                  BuyMyTrip offers you, our customer, access to and use of our
                  site subject to your acceptance without modification of the
                  Terms and Conditions contained herein. By using or obtaining
                  any content, materials, data, information, product BuyMyTrip,
                  or services through our site, you agree to the following
                  Terms:
                </p>
                <p>
                  Unaccompanied Minors: Children age 5 through 14 years may be
                  required to use the airline's Unaccompanied Minor service.
                  Additionally, you may be required to pay a fee to use this
                  service, which is usually charged at check-in with specific
                  airlines. Unaccompanied minor policies and fees vary by
                  airline; please check with your airline for specific policies
                  and fees.
                </p>

                <p>
                  Airline Fees: We reserve the right to charge a processing fee
                  for issuance of your airline ticket, car rental, hotel
                  reservation, cruise, or tour booking.
                </p>
                <p>
                  EXCURSION AND PROMOTIONAL FARES. Most discount fares involve
                  restrictions. A change in carrier(s), flight(s), time(s), or
                  routing(s) could result in a carrier demanding a full fare.
                  Obtain agency or airline assistance before making changes. If
                  your plans change enroute, apply the value of your tickets
                  toward a re-issued one. It is not necessary to purchase a new
                  ticket. If a refund is due, obtain a receipt from the airline.
                  Departure times, flights and fares are based on current
                  tariffs that are subject to change without notice.
                </p>

                <p>
                  Changes, Voids, Cancellations: BuyMyTrip This is in addition
                  to the airlines'exchange fees. The airline rules will be
                  quoted to the customer before initiating an exchange.
                  Additionally, some airline tickets issued are non-refundable
                  and nonexchangeable. There may also be a fee assessed in the
                  event of a refund or cancellation of the previously above
                  stated, imposed by the Travel Supplier. Refunds from suppliers
                  will be made to our customers only after we have received the
                  money from those sources (supplier rules and restrictions will
                  apply).
                </p>
                <p>
                  LIMITATION. You may use our web site for personal and
                  non-commercial purposes only. You may not modify, copy,
                  distribute, transmit, display, reproduce, publish, license,
                  create derivative works from, transfer, upload, post or sell
                  any information, software, product BuyMyTrip, or services
                  obtained from this web site. Trademarks, logos and service
                  marks displayed on our site are ours, and our suppliers'
                  registered and common law marks. Use of and access to our site
                  does not grant you any license or right to use any of the
                  trademarks.
                </p>
                <p>
                  LIABILITY DISCLAIMER. ALL INFORMATION, CONTENT, DATA,
                  MATERIALS, Product BuyMyTrip, AND SERVICES ARE PROVIDED ON AN
                  'AS IS' BASIS. BuyMyTrip AND ITS SUPPLIERS MAKE NO WARRANTY OF
                  ANY KIND REGARDING OUR SITE. ALL INFORMATION, PRICES, AND
                  AVAILABILITY ARE SUBJECT TO CHANGE WITHOUT PRIOR NOTICE. WE
                  EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY THAT OUR
                  SITE WILL BE ERROR-FREE, OR UNINTERRUPTED. ANY WARRANTY AS TO
                  THE ACCURACY, COMPLETENESS AND TIMELINESS OF CONTENT ON OUR
                  SITE IS FURTHER DISCLAIMED. WE SHALL NOT BE RESPONSIBLE FOR
                  BREACH OF CONTRACT OF ANY INTENTIONAL OR CARELESS ACTIONS OR
                  OMISSIONS ON THE PART OF SUCH SUPPLIERS, WHICH RESULT IN ANY
                  LOSS, DAMAGE, DELAY, OR INJURY TO YOU OR YOUR TRAVELING
                  COMPANIONS OR GROUP MEMBERS. UNLESS THE TERM 'GUARANTEED' IS
                  SPECIFICALLY STATED IN WRITING ON YOUR TICKETS, INVOICE, OR
                  RESERVATION ITINERARY, WE DO NOT GUARANTEE ANY OF SUCH
                  SUPPLIERS' RATES, BOOKINGS, RESERVATIONS, CONNECTIONS,
                  SCHEDULING, OR HANDLING OF PERSONAL EFFEBuyMyTrip AND LUGGAGE.
                  WE SHALL NOT BE RESPONSIBLE FOR ANY INJURIES, DAMAGES, OR
                  LOSSES CAUSED TO ANY TRAVELER IN CONNECTION WITH TERRORIST
                  ACTIVITIES, SOCIAL OR LABOR UNREST, MECHANICAL OR CONSTRUCTION
                  FAILURES OR DIFFICULTIES, DISEASES, LOCAL LAWS, CLIMATIC
                  CONDITIONS, ABNORMAL CONDITIONS OR DEVELOPMENTS, OR ANY OTHER
                  ACTIONS, OMISSIONS, OR CONDITIONS OUTSIDE OUR CONTROL. THE
                  TRAVELER ASSUMES COMPLETE AND FULL RESPONSIBILITY FOR, AND
                  HEREBY RELEASES THE AGENT FROM, ANY DUTY OF CHECKING AND
                  VERIFYING ANY AND ALL PASSPORT, VISA, VACCINATION, OR ANY
                  OTHER ENTRY REQUIREMENTS FOR EACH DESTINATION, AND ALL SAFETY
                  AND SECURITY CONDITIONS OF SUCH DESTINATIONS, DURING THE
                  LENGTH OF THE PROPOSED TRAVEL. FOR INFORMATION CONCERNING
                  POSSIBLE DANGERS AT INTERNATIONAL DESTINATIONS, CONTACT THE
                  TRAVEL ADVISORY SECTION OF THE U.S. STATE DEPARTMENT AT
                  202.647.5225. FOR MEDICAL INFORMATION, CALL THE US CENTER FOR
                  DISEASE AT 404.332.4559 OR VISIT THEIR WEBSITE, www.cdc.gov.
                  BY EMBARKING UPON HIS/HER TRAVEL, THE TRAVELER VOLUNTARILY
                  ASSUMES ALL RISKS INVOLVED IN SUCH TRAVEL, WHETHER EXPECTED OR
                  UNEXPECTED. THE TRAVELER IS HEREBY WARNED OF THE ABOVE RISKS,
                  AS WELL AS POSSIBLE TRAVEL INDUSTRY BANKRUPTCIES AND MEDICAL
                  AND CLIMATIC DISRUPTIONS, AND IS ADVISED TO OBTAIN APPROPRIATE
                  INSURANCE COVERAGE AGAINST THEM, WHICH IS AVAILABLE AT AN
                  EXTRA COST THROUGH BuyMyTrip . THE TRAVELER'S RETENTION OF
                  TICKETS, RESERVATIONS, TOUR DOCUMENTS, CRUISE DOCUMENTS,
                  TRAVEL AGENCY INVOICE OR BOOKINGS AFTER ISSUANCE SHALL
                  CONSTITUTE A CONSENT TO THE ABOVE AND AN AGREEMENT ON HIS/HER
                  PART TO CONVEY THE CONTENTS HERETO TO HIS/HER TRAVEL
                  COMPANIONS OR GROUP TRAVEL MEMBERS.
                </p>
                <p>
                  LINKS. Our site contains links to other sites, which we do not
                  control or operate. These links are provided for your
                  convenience and reference. You are free to access these other
                  sites, but you do so at your own risk.
                </p>
                <p>
                  PRIVACY. Use of our site is subject to our Privacy Policy.
                  Acceptance of our Terms and Conditions warrants acceptance of
                  our Privacy Policy.
                </p>

                <p>
                  IMPORTANT. Please check your travel documents when you receive
                  them. You may call us at Phone: +1 6592512000 Toll Free with
                  any questions.
                </p>
                <p>
                  CHECK-IN. Minimum check-in time for domestic flights is 1 to 1
                  1/2 hours; 2 hours for international flights. A Picture I.D.
                  is required for all domestic flights. Passports and visas
                  (when required) are mandatory for all international flights.
                </p>
                <p>
                  RECONFIRMATION. Domestic flights should be reconfirmed in
                  cases of inclement weather situations or if a flight was
                  booked some length of time prior to departure and
                  international flights at least 72 hours prior to departure. .
                  Failure to use any reservations may result in automatic
                  cancellation of all continuing and return reservations.
                </p>
                <p>
                  EXCURSION AND PROMOTIONAL FARES. Most discount fares involve
                  restrictions. A change in carrier(s), flight(s), time(s), or
                  routing(s) could result in a carrier demanding a full fare.
                  Obtain agency or airline assistance before making changes. If
                  your plans change enroute, apply the value of your tickets
                  toward a re-issued one. It is not necessary to purchase a new
                  ticket. If a refund is due, obtain a receipt from the airline.
                  Departure times, flights and fares are based on current
                  tariffs that are subject to change without notice.
                </p>
                <p>
                  TICKETS. Canceled or unused tickets (paper) must be returned
                  for proper credit to your account (airline rules and
                  restrictions will apply). Unused E-tickets should be reported
                  to BuyMyTrip to be logged and used toward future travel. Lost,
                  stolen or destroyed tickets (paper) must be paid for until
                  refund is received from the issuing carrier, subject to an
                  airline imposed service charge
                </p>
                <p>
                  HOTELS are usually confirmed on a guaranteed payment basis. If
                  you cancel or change plans, please notify your hotel(s) within
                  the time period specified. No refunds will be issued for late
                  check-in or early check-out. Stay extensions require a new
                  reservation. Resort fee is applicable subject to hotels
                  discretion. The price shown DOES NOT include any applicable
                  hotel service fees, charges for optional incidentals (such as
                  minibar, snacks, resort fee, parking fee, valet fee or
                  telephone calls), or regulatory surcharges. The lodging will
                  assess these fees, charges, and surcharges upon check-out.
                  This hotel requires that you are at least 21 to check in. Base
                  rates are displayed as per the number of occupancy mentioned
                  during search. Unless specified otherwise, rates are quoted in
                  US dollars. In case of late arrival, you need to notify the
                  hotel prior to your check in time. Your credit card will be
                  charged for the complete payment at the time of booking.
                  Prices and room availability are not guaranteed until full
                  payment is received. In case of credit card verification
                  failure the reservation will be cancelled without any prior
                  notification
                </p>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-center my-5">
      <v-btn
        style="
          color: green;
          outline: 1px solid;
          width: 200px;
          height: 45px;
          border-radius: 4px;
        "
        class="header-btn"
        prepend-icon="mdi-printer"
        @click="seleceprint3()"
        >Print</v-btn
      >
    </div>
    <!-- </v-container> -->
  </v-container>

  <v-dialog v-model="dialog1" persistent>
    <div>
      <div class="trip1">
        <v-card
          style="padding: 50px; margin-top: 50px; overflow: unset"
          width="600px"
          rounded="3"
        >
          <div class="d-flex justify-end">
            <v-icon @click="dialog1 = false" size="25" color="red">
              mdi-close-circle-outline
            </v-icon>
          </div>
          <div class="d-flex justify-center">
            <h4>Modify your booking</h4>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <span> Booking Reference </span>
              <br />
              <span style="font-size: 25px; font-weight: 600">{{
                tripId
              }}</span>
            </div>

            <div class="col-md-6">
              <v-select
                variant="outlined"
                v-model="bookstatus"
                :items="[
                  'Cancel Booking',
                  'Date Change',
                  'Upgrade Booking',
                  'Request Addons',
                ]"
              ></v-select>
            </div>
          </div>

          <div v-if="datechange">
            <div class="row">
              <div class="col-md-4 d-flex justify-center">
                <h6>Outbound Between</h6>
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate1"
                  :min-date="new Date()"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate2"
                  :min-date="dedate1"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-4 d-flex justify-center">
                <h6>Inbound Between</h6>
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate3"
                  :min-date="dedate1"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
              <div class="col-md-4">
                <VueDatePicker
                  ref="dpRef1"
                  v-model="dedate4"
                  :min-date="dedate3"
                  :six-weeks="sixWeekMode"
                  :max-date="
                    new Date(
                      new Date().getFullYear() + 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  "
                  :format="dateFormat"
                  :hide-navigation="['time']"
                  auto-apply
                  :multi-calendars="multiCalender"
                  class="px-0"
                />
              </div>
            </div>
          </div>

          <div class="d-flex justify-center" v-if="requstup">
            <p>
              Please mention your request below.Our support team will be in
              touch with you at the soonest posible
            </p>
          </div>

          <div>
            <div>Notes</div>
            <div class="mt-1 col-12">
              <v-textarea variant="outlined"></v-textarea>
            </div>
          </div>

          <div class="d-flex justify-center mt-4">
            <v-btn
              width="250px"
              color="#ff6b0a"
              @click="modifyreq(bookstatus)"
              style="height: 40px !important"
              >Request</v-btn
            >
          </div>
        </v-card>
      </div>

      <!-- <div class="d-flex justify-center " style="    flex-flow: wrap;">
 <p>
     We have received your request.Our customer support team will get back to you at the soonest possible
 </p>
 
 <p>
     Please note that  all notification requests are subject to the airline team conditions,
     and may have applicable charges
 </p>
 </div> -->
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2" persistent>
    <v-card class="w-50 m-auto py-4">
      <div class="d-flex justify-center flex-wrap">
        <h3>Your modification request has been recorded.</h3>
        <h5>Our customer support executive will get in touch with you soon.</h5>
      </div>

      <v-btn
        width="160px"
        color="#ff6b0a"
        class="m-auto mt-4"
        @click="this.dialog2 = false"
        >OK</v-btn
      >
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog3" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">Invalid PNR</h3>
        <!-- <h5>Your request cannot be processed at this moment.</h5>
        <h6>Please contact customer support.</h6> -->
        <!-- <h5 class="text-center">Invalid PNR</h5> -->
        <h6 class="text-center">please try again</h6>
        <div class="d-flex mt-3">
          <v-btn @click="tryAgain1()" color="#ff6b0a">Try Again</v-btn>

          <v-btn class="ms-2" to="/" color="#ff6b0a">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="managePopup" persistent>
    <div class="d-flex justify-center managePopup">
      <v-card class="p-4" width="750px">
        <div class="d-flex justify-content-between mb-2">
          <span class="invisible"></span>
          <h3>Manage Trip</h3>
          <span
            ><v-icon @click="(managePopup = false), (modifyCheckBox = false)"
              >mdi-close</v-icon
            ></span
          >
        </div>
        <h5>For more information, contact us</h5>

        <div>
          <div class="mt-2 f-size-12">
            <h6>How much it costs</h6>
            <span
              >If changes are allowed by the airline, the following charges
              apply:</span
            >
            <ul class="mt-2">
              <li>
                airline's penalty when included in the ticket's fare rules
              </li>
              <li>
                our service fee of 56 CAD per person (not applicable for
                Platinum Support Package)
              </li>
              <li>any difference in fare and taxes from the original trip</li>
            </ul>
          </div>

          <div class="mt-2 f-size-12">
            <h6>How to change a ticket</h6>
            <span
              >If changes are allowed by the airline, the following charges
              apply:</span
            >
            <ul class="mt-2">
              <li>
                airline's penalty when included in the ticket's fare rules
              </li>
              <li>
                our service fee of 56 CAD per person (not applicable for
                Platinum Support Package)
              </li>
              <li>any difference in fare and taxes from the original trip</li>
            </ul>
          </div>
        </div>

        <div class="border border-1 border-gray p-2">
          <v-form
            ref="form"
            v-model="validForm"
            @submit.prevent="toggleTawkToChat()"
          >
            <div>
              <div class="p-2 bg-gray">
                <span>Recommended</span>
              </div>
            </div>
            <div>
              <v-icon>mdi-chat-processing</v-icon>
              <span class="ms-2">Chat</span>
            </div>
            <div
              class="d-flex justify-center align-center checked-box"
              style="max-width: fit-content"
            >
              <v-checkbox
                v-model="modifyCheckBox"
                @click="errorMsg = false"
                class="pe-3 ps-0 checkBox"
                style="flex: none"
              ></v-checkbox>
              <span class="agree-content f-size-12"
                >By using this chat I allow my data to be stored for security
                and quality review as mentioned in the Privacy Policy.
              </span>
            </div>
            <p
              v-if="errorMsg"
              class="w-50 ps-4 m-0 f-size-12 fw-400"
              style="color: #b00020"
            >
              You must agree to continue!
            </p>
            <div class="text-center">
              <!-- <v-btn @click="javascript:void(Tawk_API.toggle())" rounded color="primary" dark>Start Live Chat</v-btn> -->
              <v-btn rounded color="primary" type="submit" dark
                >Start live chat</v-btn
              >
            </div>
          </v-form>
        </div>

        <div
          class="border border-1 border-gray p-2 d-flex justify-content-between mt-2"
        >
          <div @click="managePopup = false">
            <v-icon>mdi-email</v-icon>
            <a class="ms-2" :href="'mailto:' + emailPortal">{{
              emailPortal
            }}</a>
          </div>
          <div class="invisible">
            <v-icon>mdi-menu-down-outline</v-icon>
          </div>
        </div>

        <div class="mt-3">
          <span class="f-size-12"
            >Please note that a support ticket will only be created for
            inquiries submitted through channels listed on this page. We will
            not respond to messages received outside of these options, including
            but not limited to those sent via regular mail and sent directly to
            employees</span
          >
        </div>

        <!-- <div class="d-flex mt-3">
          <v-btn @click="tryAgain1()" color="#ff6b0a">Try Again</v-btn>

          <v-btn class="ms-2" to="/" color="#ff6b0a">Return to Home</v-btn>
        </div> -->
      </v-card>
    </div>
  </v-dialog>

  <div v-if="overlay">
    <dataloader></dataloader>
  </div>
</template>

<script>
import moment from "moment";
import airLogo from "@/airlinesLogo.json";
import dataloader from "@/components/dataLoder.vue";
import userData from "@/airport_icao.json";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    VueDatePicker,
    dataloader,
  },
  data() {
    return {
      bookid: "",
      lastname: "",

      showConfirmPage: false,
      datavkeer: true,
      dialog: false,
      // valid: false,
      viewbook: true,
      dateFormat: "dd MMM yyyy",
      overlay: false,
      mybooking: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      bookstatus: "Cancel Booking",
      requstup: false,
      datechange: false,
      dedate1: "",
      dedate2: "",
      dedate3: "",
      dedate4: "",
      retriveData: [],
      itineryData: [],
      searchcabintype: "",
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      mobilebtn: true,
      contactnum: "",
      passEmail: "",
      bookingStatus: "",
      bookingTime: "",
      countrycode: "",
      Flight1: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      multicity: [],
      Eticketno: false,
      rount: false,
      oneway: false,
      multi: false,

      managePopup: false,
      modifyCheckBox: false,

      validForm: false,

      portal_sitetitle: "",
      getPassid: "",
      retreiveBookApi: "",
      portalId: "",
      portalLogo: "",

      btnActive: true,

      emailPortal: "",

      errorMsg: false,
    };
  },

  // computed: {
  //   window.history.forward();
  //       function noBack() {
  //           window.history.forward();
  //       }
  // },

  methods: {
    tryAgain1() {
      this.dialog3 = false;
      this.$router.push("/mybooking");
      // this.retrivingBooking();
    },

    toggleTawkToChat() {
      if (this.validForm && this.modifyCheckBox) {
        this.managePopup = false;
        if (window.Tawk_API) {
          window.Tawk_API.toggle();
        }
      } else {
        this.errorMsg = true;
        if (!this.validForm) {
          return this.errorMsg;
        }
      }
    },

    seleceprint1() {
      const printContent = document.getElementById("printMe");
      if (printContent) {
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContent.innerHTML;
        window.print();
        document.body.innerHTML = originalContents;
      } else {
        console.error("Element with ID 'printMe' not found..1");
      }
    },

    multigetdepcode(data) {
      console.log(data, "ppppp");
      return data[0].departure_city_name;
    },
    multigetarrcode(data) {
      return data[data.length - 1].departure_city_name;
    },
    multigetdeptime(data) {
      return moment(data[0].depart_datetime).format("DD-MMM-YYYY");
    },

    modify(data) {
      this.dialog1 = true;

      if (data == "cancel") {
        this.bookstatus = "Cancel Booking";
      } else {
        this.bookstatus = "Date Change";
      }
    },

    modifyreq(data) {
      console.log(data, "ppppp");
      this.overlay = true;
      let result = "";
      if (data == "Cancel Booking") {
        result = "cancel";
      } else if (data == "Date Change") {
        result = "date_change";
      } else if (data == "Upgrade Booking") {
        result = "upgrade";
      } else if (data == "Request Addons") {
        result = "addon";
      }

      let modify = {
        request_type: "modify_request",
        booking_id: this.tripId,
        modify_request_type: result,
        outbound_date_between: "",
        inbound_date_between: "",
        notes: "test",
      };

      modify.outbound_date_between = `${moment(this.dedate1).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate2).format("YYYY-MM-DD")}`;
      modify.inbound_date_between = `${moment(this.dedate3).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate4).format("YYYY-MM-DD")}`;

      console.log(modify, "modifymodify");
      // console.log(this.modifyBookingUrl,'this.modifyBookingUrl........')

      axios
        .post(this.modifyBookingUrl, modify, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response, "response99999999999999999999999999");
          if (response.data.modify_response == "success") {
            setTimeout(() => {
              this.overlay = false;
              this.dialog1 = false;
              this.dialog2 = true;
            }, 2000);
          } else {
            this.dialog3 = true;
            this.overlay = false;
          }
          console.log(response.data.modify_response, "success");
        })
        .catch((error) => {
          setTimeout(() => {
            this.dialog3 = true;
            this.overlay = false;
          }, 2000);

          console.log(error, "modify");
        });
    },

    getdob($event) {
      let birthDate = moment($event).format("DD-MMM-YYYY");
      let currentDate = moment();
      let age = currentDate.diff(birthDate, "years");

      console.log("Age:", age);
      return age;
    },
    getdepdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("ddd MMMM D, YYYY");
    },
    getarrtime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode(data) {
      console.log(data, "pppp");
      return data[0].departure_city_name;
    },
    getarrcode(data) {
      console.log(data, "pppp");
      return data[data.length - 1].arrival_city_name;
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getdepdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("ddd MMMM D, YYYY");
    },
    getdeptime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    // getdepcode1(data) {
    //   console.log(data, "dataaaaaaaaaaaaaaaa");
    //   if (data) {
    //     return data[0].depart_airport;
    //   }
    // },
    // getarrcode1(data) {
    //   if (data) {
    //     return data[data.length - 1].arrival_airport;
    //   }
    // },

    getstarttime1(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getOnewaystarttime1(data) {
      let depdata = data[data.length - 1].arrival_datetime.split("T");
      console.log(data, "depdatadepdata....depdatadepdata");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    capitalizeLastName() {
      this.lastname =
        this.lastname.charAt(0).toUpperCase() + this.lastname.slice(1);
    },

    bookingretrive() {
      this.airlogodata = airLogo;
      // setTimeout(() => {
      //   this.overlay = false;
      //   this.showConfirmPage = true;
      // }, 5000);
      if (this.retriveData.length > 0) {
        //      this.arrivalData = [];
        // this.depatureData = [];
        this.retriveData.forEach((v) => {
          console.log(v, "dtaswwwdjffjkfjkadfkfadjkdjkadjk");
          this.baseprice = parseFloat(v.fare_info.price_per_person).toFixed(2);
          if (v.fare_info.itin_currency == "USD") {
            this.currency = "US$";
          } else if (v.fare_info.itin_currency == "CAD") {
            this.currency = "CA$";
          } else {
            this.currency = v.fare_info.itin_currency;
          }
          this.totalprice = parseFloat(v.fare_info.itin_total_fare).toFixed(2);
          this.passengerDetail = v.pax_info;
          //  this.Eticketno=v.pax_info.
          this.itineryData = v.itinerary;
          this.contactnum = v.contact_info.paxphone;
          this.passEmail = v.contact_info.paxemail;
          this.tripId = v.booking_id;
          this.bookingStatus = v.status;
          if (this.bookingStatus == "ticketed") {
            this.Eticketno = true;
          } else {
            this.Eticketno = false;
          }
          console.log(
            this.bookingStatus,
            "bookingStatusbookingStatusbookingStatus"
          );
          this.bookingTime = moment(v.booking_at).format("DD MMM YYYY HH:MM");
          this.searchcabintype = v.search_cabin_type;
          this.countrycode = v.contact_info.country_code;

          for (let i = 0; i < this.airlogodata.length; i++) {
            this.itineryData.forEach((a) => {
              if (this.airlogodata[i].id == a.carrier) {
                a.logo = this.airlogodata[i].logo;
                a.flightname = this.airlogodata[i].name;
              }
            });
          }

          console.log(this.datareurn, "this.datareurnthis.datareurn");

          Object.keys(userData).forEach((icao) => {
            const airport = userData[icao];
            if (airport.iata !== "") {
              this.fromAirports.push({
                iata: airport.iata,
                icao: airport.icao,
                places: `${airport.name}`,
              });
            }
          });

          for (let i = 0; i < this.fromAirports.length; i++) {
            this.itineryData.forEach((a) => {
              if (this.fromAirports[i].iata == a.depart_airport) {
                a.depAirportName = this.fromAirports[i].places;
              }
              if (this.fromAirports[i].iata == a.arrival_airport) {
                a.arrAirportName = this.fromAirports[i].places;
              }
            });
          }
          this.itineryData.forEach((j) => {
            console.log(this.itineryData, "yd999999999gvashyvveifkk");

            if (
              v.search_trip_type == "multi" ||
              this.itineryData[this.itineryData.length - 1].flight_line > 2
            ) {
              this.triptype = "MULTICITY";
              // alert('11')
              this.multi = true;
              if (j.flight_line == 1) {
                this.Flight1.push(j);
                console.log(this.Flight1, "11");
              }
              if (j.flight_line == 2) {
                this.Flight2.push(j);
                console.log(this.Flight2, "22");
              }
              if (j.flight_line == 3) {
                this.Flight3.push(j);
                console.log(this.Flight3, "33");
              }
              if (j.flight_line == 4) {
                this.Flight4.push(j);
                console.log(this.Flight4, "44");
              }
            } else if (
              v.search_trip_type == "roundtrip" ||
              this.itineryData[this.itineryData.length - 1].flight_line == "2"
            ) {
              // alert('1122')
              console.log("routtripe");
              this.rount = true;
              this.triptype = "ROUND TRIP";
              if (j.flight_line == 1) {
                this.depatureData.push(j);
              } else if (j.flight_line == 2) {
                this.arrivalData.push(j);
              }
            } else if (
              v.search_trip_type == "oneway" ||
              this.itineryData[this.itineryData.length - 1].flight_line == "1"
            ) {
              // alert('1133')
              console.log("oneway");
              this.oneway = true;
              this.triptype = "ONEWAY TRIP";
              this.depatureData.push(j);
            }
          });
          // this.itineryData.forEach((s) => {
          //   if (s.flight_line == 2) {

          //   } else if (s.flight_line == 1) {

          //   }
          // });
          console.log(this.depatureData, "this.depatureData");
          console.log(this.arrivalData, "this.depatureData");

          console.log(
            this.multicity,
            "this.multicitythis.multicitythis.multicity"
          );
        });
      }
      this.getBookingStatus();
      this.gettimedep();
    },

    gettimedep() {
      let result = [];

      for (let i = 0; i < this.itineryData.length - 1; i++) {
        console.log(this.itineryData[i].depart_datetime, "popppopoppopop");

        const time1 = new Date(this.itineryData[i + 1].depart_datetime);
        const time2 = new Date(this.itineryData[i].arrival_datetime);

        const diffInMilliseconds = Math.abs(time1 - time2);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        result.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }

      this.itineryData.forEach((v, j) => {
        v.overlayTime = result[j];
      });

      console.log(this.itineryData, "itineryDataitineryData");
    },

    getColor() {
      if (
        this.bookingStatus == "cancelled" ||
        this.bookingStatus == "ticketed"
      ) {
        return "danger";
      } else if (this.bookingStatus === "booked") {
        return "success";
      } else {
        return "warning";
      }
    },

    getBookingStatus(bookingStatus) {
      if (/[A-Z]/.test(bookingStatus)) {
        if (
          bookingStatus.toLowerCase() === "cancelled" ||
          bookingStatus.toLowerCase() === "ticketed"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (bookingStatus === "cancelled" || bookingStatus === "ticketed") {
          return false;
        } else {
          return true;
        }
      }
    },

    flighttimeData(data) {
      return Math.floor(data.duration / 60);
    },
    flighttimeData1(data) {
      return data.duration % 60;
    },

    getConfig() {
      // let getConfigData = this.$store.state.data

      const getConfigData = getConfigDataFromLocalStorage();

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      if (getConfigData) {
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;

        this.portal_sitetitle =
          getConfigData.payload.portal_configuration.site_title;
        this.modifyBookingUrl =
          getConfigData.payload.portal_configuration.API_endpoints.modify_booking;
        this.portalId = getConfigData.payload.portal_configuration.portal_id;

        if (this.getPassid.includes("env")) {
          this.retreiveBookApi =
            getConfigData.payload.portal_configuration.API_endpoints.meta_retreive;
          console.log(
            this.retreiveBookApi,
            "retreiveBookApiretreiveBookApi....1"
          );
        } else {
          this.retreiveBookApi =
            getConfigData.payload.portal_configuration.API_endpoints.retreive_booking;
          console.log(
            this.retreiveBookApi,
            "retreiveBookApiretreiveBookApi....2"
          );
        }
      }
    },

    async retrivingBooking() {
      this.booking_refId = JSON.parse(this.$route.query.booking_id);
      let retreivebooking = {
        request_type: "retreive_booking",
        booking_id: this.booking_refId.booking_Id,
        pnr: this.booking_refId.pnr,
        last_name: this.booking_refId.last_name,
        portal_id: this.portalId,
      };

      console.log(retreivebooking, "retreivebookingretreivebooking");

      await axios
        .post(this.retreiveBookApi, retreivebooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response, "vasanth");
          if (response.data) {
            setTimeout(() => {
              this.overlay = false;
              this.showConfirmPage = true;
            }, 5000);
          }

          console.log(response.data, "retrievebooking555555555.........2999");
          this.retriveData.push(response.data);

          if (this.retriveData.length > 0) {
            this.bookingretrive();
          }
        })
        .catch((error) => {
          console.log(error, "retreivebooking......2");

          setTimeout(() => {
            this.dialog3 = true;
          }, 20000);
        });

      this.local_id = this.$route.query.pass_id;
      this.Localoneway_id = this.$route.query.pass_uid;
      localStorage.setItem("delete_Id", this.Localoneway_id);
      localStorage.setItem("delete_Id", this.local_id);
    },
  },

  mounted() {
    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    console.log(this.dateOfBirth, "dobdob.....");

    // const s1 = document.createElement("script");
    // const s0 = document.getElementsByTagName("script")[0];
    // s1.async = true;
    // s1.src = 'https://embed.tawk.to/661e1b5f65e07e/1hnlmkj';
    // s1.charset = 'UTF-8';
    // s1.setAttribute('crossorigin', '*');
    // s0.parentNode.insertBefore(s1, s0);
  },

  watch: {
    bookstatus(newVal) {
      console.log(newVal, "newVal");
      if (newVal == "Cancel Booking") {
        this.datechange = false;
        this.requstup = false;
      } else if (newVal == "Date Change") {
        this.datechange = true;
        this.requstup = false;
      } else if (newVal == "Upgrade Booking" || newVal == "Request Addons") {
        this.datechange = false;
        this.requstup = true;
      }
    },
  },

  created() {
    this.overlay = true;
    this.getPassid =
      this.$route.query.pass_id ||
      this.$route.query.pass_uid ||
      this.$route.query.booking_id;
    // console.log(this.getPassid, "this.getPassidthis.getPassid....1");
    // console.log(this.$route.query.pass_id, "this.getPassidthis.getPassid....2");
    // console.log(
    //   this.$route.query.pass_uid,
    //   "this.getPassidthis.getPassid....3"
    // );
    // console.log(
    //   this.$route.query.booking_id,
    //   "this.getPassidthis.getPassid....4"
    // );

    this.getConfig();

    this.retrivingBooking();

    // this.bookingretrive()
  },
};
</script>

<style scoped>
/* .booking-card{
  font-family: sans-serif;
} */

@import "@/assets/main.css";
.f-size-20 {
  font-size: 20px;
}

.f-size-16 {
  font-size: 16px;
}
.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.iconSize {
  font-size: 24px;
}

.ItinHead {
  font-size: 16px;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}

/* .booking-text p{
  font-size: 17px;
  font-weight: 400;
} */

>>> .checkBox .v-selection-control__wrapper {
  width: 20px !important;
}

>>> .managePopup .v-btn .v-btn__content {
  font-size: 14px;
  font-weight: 600;
}

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.Booking-status {
  font-size: 12px;
  text-transform: uppercase;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

.trip1 {
  justify-content: center;
  display: flex;
  padding: 15px;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

/* .f-family {
  font-family: sans-serif;
  font-size: 15px;
} */

.MyAirBudget1 {
  display: none;
}

/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .print-btn-1 {
    display: flex;
  }

  .print-btn-mbl {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }

  .print-btn-mbl {
    display: block;
  }

  .print-btn-1 {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .hide-btn-desk .desk-Btn {
    display: none !important;
  }

  .mbl-Btn {
    display: block !important;
  }
}

@media only screen and (min-width: 577px) {
  .mbl-Btn {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  .mobile-table {
    display: none;
  }

  /* .desk-Btn {
    display: block !important;
  } */
}

@media only screen and (max-width: 767px) {
  .iconSize {
    font-size: 18px;
  }

  .ItinHead {
    font-size: 13px;
  }

  .arrMargin {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .Booking-id {
    font-size: 18px;
    font-weight: 700;
  }

  .Booking-status {
    font-size: 12px;
    text-transform: uppercase;
  }

  .mbl-Btn {
    display: block !important;
  }

  .desk-Btn {
    display: none !important;
  }

  .contion {
    display: block;
  }

  .MyAirBudget {
    display: none;
  }

  .MyAirBudget1 {
    display: block;
  }

  .mobile-table {
    display: block;
  }

  /* .desktop-table {
    display: none;
  } */

  .print-btn-1 {
    display: none;
  }

  .f-family {
    font-size: 12px;
  }

  .airlogo-size {
    width: 25px !important;
  }

  .airline-name {
    font-size: 10px;
  }

  .f-size-16 {
    font-size: 16px;
  }

  .TERMS {
    display: none;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 600;
  }
}

>>> .v-textarea .v-field--no-label textarea,
.v-textarea .v-field--active textarea {
  opacity: 1;
  height: 50px;
}

>>> .dp__pointer {
  cursor: pointer;
  font-size: 13px;
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  /* margin: 0px 7px; */
  color: green;
  outline: 1px solid;
  width: 100px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.view_mobile {
  display: none;
}

@media only screen and (max-width: 480px) {
  .f-size-20 {
    font-size: 14px;
    font-weight: 500;
  }

  .f-size-14 {
    font-size: 10px;
  }

  .f-size-13 {
    font-size: 12px;
  }

  .f-size-12 {
    font-size: 10px;
    font-weight: 500;
  }

  .fw-500 {
    font-weight: 600;
  }

  .view_mobile {
    display: block;
  }

  .view_tap {
    display: none;
  }

  .bookingInfoCard {
    padding: 0px 4px !important;
  }

  .bookTripId-info {
    width: 35% !important;
  }

  .bookPlace-info {
    width: 65% !important;
  }

  .bookDate-info {
    width: 40% !important;
  }

  .bookTrip-info {
    width: 40% !important;
  }

  .pass-icon {
    margin-left: 0px !important;
  }
  .pass-name {
    margin-left: 4px !important;
  }
  .pass-info-1 {
    width: 68%;
  }

  .pass-info-2 {
    width: 32%;
  }

  .price_margin {
    margin-bottom: 12px;
  }

  .margin-less {
    margin: 0px;
  }
}
</style>
