<template>
    <header class="header z-in-2 page-header" id="home" :class="isShrink ? 'shrink position-fixed' : ''">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light py-0 px-0">
              <a class="navbar-brand" href="/" style="border-radius:20px;padding:10px;margin-top: 3px;">
                <img
                  :src="portalLogo" style="width:230px;"
                  alt="Brand Logo"
                  title="Brand Logo"
                  class="img-fluid"/></a>
              <button class="navbar-toggler px-1 btn rounded-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="isCollapseShown = !isCollapseShown"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto align-center px-3">
                  <li class="nav-item meee...1" v-if="menuData.includes('country')">
                    <div class="dropdown-container pe-md-4">
                      <div class="click-dropdown"  :class="countryOptions.length > 1 ? 'dropdown-toggle' : ''" 
                          @click="toggleDropdown('currency')">
                        <div class="d-flex align-center lh-base"> 
                          <div>
                            <img :src="display_flagLogo"  style="border-radius:20px;" 
                            :style="{ width: !isShrink ? '30px' : '25px', height: !isShrink ? '30px' : '25px' }"/>
                          </div>
                            <span class="ms-2 lh-normal">{{ portalLanguage}} {{currencyData.display }}</span>
                        </div> 
                        <!-- <i class="bi bi-currency-exchange"></i> Currency -->
                        <!-- <i class="bi bi-currency-exchange"></i> Currency -->
                      </div>

                      
                      <div v-if="countryOptions.length > 1 && activeDropdown === 'currency'"  class="dropdown-menu"
                         :class="domainInfo ? 'readOnly' : ''" v-click-outside="onClickOutside">
                        <ul>
                          <li class="nav-item" v-for="(country, index) in countryOptions" :key="index">
                            <div v-if="country.currency !== currencyData.display">
                              <a class="dropdown-item" :href="country.portal_url">
                                <!-- <span class="flag in"></span> -->
                                <div class="d-flex align-center">
                                  <div>
                                    <v-img :src="country.flag_logo" width="30px" height="30px" style="border-radius:20px;" />
                                  </div>
                                  <span class="ps-1 f-size-15 fw-500">{{ country.languages }}</span>
                                  <span class="ps-1 f-size-15 fw-500">{{ country.currency }}</span>
                                </div>
                              </a>
                            </div>
                          </li>
                          <!-- <li class="nav-item">
                            <a class="dropdown-item" href="#"
                              ><span class="flag us"></span>USD</a
                            >
                          </li>
                          <li class="nav-item">
                            <a class="dropdown-item" href="#"
                              ><span class="flag er"></span>EUR</a
                            >
                          </li> -->
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item meee...2"  v-if="!menuData.includes('mybookings')">
                    <div class="dropdown-container pe-md-4">
                        <a href="/mybooking" :style="{ color: mybookTextColor }" class="click-dropdown d-flex align-center text-decoration-none">
                          <span><i class="bi bi-journal-text lh-1 fs-6"></i></span>
                        <span class="ms-1">My Bookings</span>
                      </a>
                    </div>
                  </li>
                  <!-- <li class="nav-item">
                    <div class="dropdown-container pe-md-4">
                      <div class="dropdown-toggle click-dropdown"  @click="toggleDropdown('language')">
                        <i class="bi bi-translate"></i> Language
                      </div>
                      <div v-if="activeDropdown === 'language'" class="dropdown-menu">
                        <ul>
                          <li class="nav-item">
                            <a class="dropdown-item" href="#"
                              ><span class="flag us"></span>English</a
                            >
                          </li>
                          <li class="nav-item">
                            <a class="dropdown-item" href="#"
                              ><span class="flag ru"></span>Rusian</a
                            >
                          </li>
                          <li class="nav-item">
                            <a class="dropdown-item" href="#"
                              ><span class="flag fr"></span>French</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li> -->
                  <li class="nav-item meee...3" v-if="!menuData.includes('signin')">
                    <div>
                      <div class="dropdown-container">
                      <div class="dropdown-toggle click-dropdown d-flex align-center"  @click="toggleDropdown('account')">
                        <span><i class="bi bi-person-circle"></i></span>
                        <span class="ms-1">Account</span>
                      </div>
                      <div v-if="activeDropdown === 'account'" class="dropdown-menu" v-click-outside="onClickOutside">
                        <ul class="lh-base">
                          <li class="nav-item pb-0">
                            <a class="dropdown-item" href="/loginPage"
                              >Login</a
                            >
                          </li>
                          <li class="nav-item pt-0">
                            <a class="dropdown-item" href="/registerPage"
                              >Register</a
                            >
                          </li>
                        </ul>
                      </div>
                      </div>
                    </div>



                    <div v-if="userProfile" style="width: 18%; margin-left: 35px; margin-right: 35px;">
                      <v-menu style="cursor: pointer" min-width="200px" rounded open-on-hover>
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-btn icon v-bind="props">
                              <v-avatar color="light" size="large">
                                <v-icon icon="mdi-account-circle"></v-icon>
                              </v-avatar>
                            </v-btn>
                          
                            <!-- <h5 class="text-light px-2 m-0">My Profile</h5> -->
                          </div>
                        </template>
                      
                        <v-card>
                          <v-card-text>
                            <div class="mx-auto text-center">
                              <v-avatar color="brown">
                                <v-icon icon="mdi-account-circle"></v-icon>
                                <!-- <span class="text-h5">{{ user.initials }}</span> -->
                              </v-avatar>
                            
                              <p style="margin-bottom:1px !important"> {{ userEmail }}</p>
                              <p style="margin-bottom:1px !important">{{ userName }}</p>
                              <!-- <p class="text-caption mt-1">
                                {{ user.email }}
                              </p> -->
                              <v-divider v-if="menuData.includes('viewbookings')" class="my-3"></v-divider>
                              <v-btn v-if="menuData.includes('viewbookings')" rounded variant="text" @click="booking()"> View Bookings</v-btn>
                              <div>
                              </div>
                              <v-divider class="my-3"></v-divider>
                              <v-btn rounded variant="text" @click="deleteItem()">
                                Log Out
                              </v-btn>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>








                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>


  <!-- <div v-if="loginform">
    <loginCom @close="closeLogin()"></loginCom>
  </div>
  <div v-if="registerform">
    <registerCom @close="closeRegister()"></registerCom>
  </div> -->
</template>

<script>
// import loginCom from '@/components/loginCom.vue'
// import loginCom from "@/components/loginCom.vue";
// import registerCom from "@/components/registerCom.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';


// import axios from "axios";
// import { onMounted } from 'vue';


// import { onMounted } from 'vue';
// import { mapGetters } from 'vuex';
// import { useStore } from 'vuex';

// setTimeout(() => {
//   onMounted(() => {
//     // this won't work.
//     console.log(this.$store.state.data,'head')
//   })
// }, 100)

export default {

  props: {
    domainInfo: {
      type: Boolean,
      default: false
    }
  },
  // components: {

  //   loginCom,

  // },
  data() {
    return {
      loginform: false,
      registerform: false,
      selectedCountry: null,
      isSticky: false,
      conDetails: true,
      menuData:"",

      isShrink: false,


      isCollapseShown: true,
      // CurrencyItems: [
      //   { name: "EN CA$", src: require("@/assets/CA.svg"), link: "https://buymytrip.ca/" },
      //   // { name: "EN AU$", src: require("@/assets/AU.svg"), link:"https://buymytrip.com.au/"},
      //   // { name: "EN US$", src: require("@/assets/US.svg"), link:"https://buymytrip.com/"},
      //   // { name: "EN UK£", src: require("@/assets/GB.svg"), link:"https://buymytrip.co.uk/"},
      // ],

      PortMail: "us-bookings@buymytrip.com",
      PortContact: "+1-659 251 2000",

      portCurrency: "EN US$",
      portCurrencyLogo: require('@/assets/US.svg'),


      hostName: "",
      portalUrl:"",


      userDetails: true,
      userProfile: false,
      userEmail: "",
      userName: "",
      portalLogo: "",
      emailPortal: '',
      phonePortal: '',
      countryOptions: [],
      languagePortal: '',
      currencyPortal: '',

      display_flagLogo : '',

      user: {
        initials: "",
        // fullName: 'John Doe',
        email: "",
      },


      // configReq: {
      //   "service_type": "GetPortalConfigRQ",
      //   "api_ver": "1",
      //   "payload": {
      //     "domain_name": "buymytrip.com",
      //     "env": "CERT",
      //     "language": "EN",
      //     "token": "jsdjOJDKJ7675"
      //   }
      // },


      headerMenus: [],

      activeDropdown: null,
      // menuEmail:"",
      // menuPhone:"",
    };
  },
  methods: {

    onClickOutside() {
          this.activeDropdown = null;
        },
    
    toggleDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      } else {
        this.activeDropdown = dropdownName;
      }
    },

    portalLink(countryOptions) {
      return countryOptions[0].portal_url
    },
    portallogo(countryOptions) {
      return countryOptions[0].flag_logo
    },
    // portalLanguage(countryOptions) {
    //   return countryOptions[0].languages
    // },
    // portalcurrency(currencyData) {
    //   console.log(currencyData,'currencyDatacu')
    //   return currencyData.display
    // },

    handleScroll() {
      const shrinkHeader = 50;
      this.isShrink = window.pageYOffset >= shrinkHeader;
    },

    // handleScroll() {
    //   const currentScroll = window.pageYOffset;
    //   this.isSticky = currentScroll > 100;
    // },
    signIn() {
      this.loginform = !this.loginform;
      // this.registerform = false;
    },


    // onClickOutside() {
    //   this.loginform = false
    //   // console.log(this.loginform, "666666")
    // },


    closeLogin() {
      this.loginform = false;
      // console.log(this.loginform, "4444444")
    },
    register() {
      this.registerform = !this.registerform; // Toggle the value of registerform
      this.loginform = false; // Close the login form if open
    },
    closeRegister() {
      this.registerform = false;
    },

    getUser() {
      let userData = JSON.parse(localStorage.getItem("value"));
      // console.log(userData, "user....");
      if (userData) {
        this.userDetails = false;
        this.userProfile = true;
      } else {
        this.userDetails = true;
        this.userProfile = false;
      }
    },
    booking() {
      this.$router.push('/dashboard')
    },
    deleteItem() {
      localStorage.removeItem("value");
      setInterval(() => {
        location.reload();
      }, 500);
      this.userDetails = true;
      this.userProfile = false;
      this.$router.push('/')
    },

    getLoginDatas() {
      JSON.parse(localStorage.getItem("loginDatas"));
      // console.log(userItems, "loginddddd......");
      // this.user.email = userItems.userEmail
      // this.user.email = userItems.userPass
    },
    getlogo(data) {
      console.log(data, 'duifuisddsddjik')
    },

    // getCurrencyLogo(datas){
    //   console.log(datas,'datasdatasdatas')
    //   datas.forEach((v,i) => {
    //     if(i == 0){
    //       this.display_flagLogo = v.flag_logo
    //       console.log(v.flag_logo,'datasdatasdatas')

    //     }
    //   });
    // },






    getConfigFunc() {


      // Parse the decrypted data as JSON
      const getConfigData = getConfigDataFromLocalStorage();




      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)

      console.log(getConfigData, 'head...1')

      // let getConfigData = this.$store.state.data

      if (getConfigData) {

        console.log(getConfigData, "getConfigDatagetConfigData")

        let Menus = ""
        Menus = getConfigData.payload.portal_configuration.menus.enabled.header
        this.menuData=getConfigData.payload.portal_configuration.menus.enabled.header
        // console.log(this.menuData.includes('viewbookings'),'MenusMenus3333')
        this.headerMenus = Menus.split(', ')

        // getConfigData.payload.portal_configuration.siteTitle
        // this.menuEmail = this.Menus.header.email
        // this.menuPhone = this.Menus.header.phone
        // console.log(this.menuEmail,"pmpmpmpmpm...1")
        // console.log(this.menuPhone,"pmpmpmpmpm....2")

        this.portalUrl = getConfigData.payload.portal_configuration.portal_url
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path
        this.phonePortal = getConfigData.payload.portal_configuration.phone
        this.emailPortal = getConfigData.payload.portal_configuration.email
        this.currencyData = getConfigData.payload.portal_configuration.currency
        this.portalLanguage = getConfigData.payload.portal_configuration.language
        this.countryOptions = getConfigData.payload.portal_configuration.country_options;
        

      //   this.countryOptions.forEach((v,i) => {
      //   if(i == 0){
      //     this.display_flagLogo = v.flag_logo
      //     console.log(this.display_flagLogo,'datasdatasdatas')
      //   }
      // });

        this.countryOptions.forEach(v => {
          // let currencyDisplay = this.currencyData.display.substring(0, 2);
          if (v.currency == this.currencyData.display) {
            this.display_flagLogo = v.flag_logo
            console.log(this.display_flagLogo, "currencyDatacurrencyData");
          }
        });

      }
    }

  },

  computed: {
    mybookTextColor() {
      if (this.$route.path === '/' && this.isCollapseShown) {
        return 'black';
      } else if (this.$route.path === '/') {
        return 'black';
      } else {
        return 'black';
      }
    },
  },



  created() {
    // this.envPortal();
    console.log(this.domainInfo,'domainInfodomainInfo')
    this.getConfigFunc();
    let $useresult = JSON.parse(localStorage.getItem('loginuser'))
    // console.log($useresult, '$useresult $useresult ')
    if ($useresult) {
      this.userEmail = $useresult.user.email
      this.userName = $useresult.user.name
    }
  },

  mounted() {

    // setTimeout(() => {
    // this.getConfigFunc();
    // }, 500)
    // document.getElementById("navbar").style.color = "black";
    window.addEventListener("scroll", this.handleScroll);
    this.getUser();
    this.getLoginDatas();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },


};
</script>

<style>


@import "@/assets/main.css";


.f-size-20 {
  font-size: 20px;
}

.f-size-18 {
  font-size: 18px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}


.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav {
  margin-top: 10px;
}
.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}


























/* navbar */
.logo {
  width: 180px;
}

.portal_logo{
  width: 200px;
  height: 55px;
  cursor: pointer; 
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f4f5 !important;
  z-index: 999;
  animation: fadeInDown .6s ease-in-out 0s 1 normal none running;
  transition: .6s;
  box-shadow: 0px 0px 20px 0px black;

  /* Add any other styles you need for the sticky header */
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-18);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-400);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

/* .v-btn__content {
  color: #FF5400;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
} */

.bgimg {
  background-image: url("@/assets/CA.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}


.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  /* color: white; */
}

.btn-active {
  background-color: blueviolet;
  /* color: white; */
}

.v-checkbox .v-input__details {
  display: none;
}

/* .dropdown-toggle::after {
  display: none !important;
} */

.navbar-toggler {
  border: none !important;
}

/* .dropdown-toggle { */
  /* width: 125px; */
  /* height: 30px; */
  /* white-space: nowrap; */
  /* border: 1px solid !important; */
/* } */

/* .v-app-bar {
  height: 100px;
  background-image: url('../assets/flight.jpg');
  background-size: 100% 100%;
} */

/* .btn-box,
.pipe {
  color: white !important;
} */


.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

.contact-box span {
  font-size: 15px;
}

.hoverList:hover {
  background-color: lightgrey;
}

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;

}

.booking:hover {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

.booking-active {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

/* .header .dropdown-item .click-dropdown img {
  width:30px !important;
  height:30px !important;
  border-radius:30px !important;
} */






/* Header Style  */


/* .page-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.15);
}

.page-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-header button {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 14px 20px;
  border-radius: 10px;
  color: white;
  background: #08a6df;
  transition: background 0.3s;
}

.page-header button svg {
  flex-shrink: 0;
  margin-left: 5px;
  fill: currentColor;
}

.page-header button:hover {
  background: #0ab8f6;
} */

/* Header Style End  */


/* STICKY STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* .page-header.is-sticky {
  position: fixed !important;
  top: 0;
  box-shadow: 0 5px 16px #0000004a;
  padding: 0px 0;
  backdrop-filter: blur(10px);
  animation: slideDown 0.35s ease-out;
  background-color: white;
}

.page-header.is-sticky .navbar-brand img {
  width: 150px !important;
} */






/* .page-header.is-sticky img {
  max-width: 80%;
}

.page-header.is-sticky button {
  font-size: 14px;
  padding: 7px 10px;
} */





/* @keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
} */


/* STICKY STYLES End */







/* Header Style  */


/* .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #cc5350;
    color:#fff;
    z-index: 1000;
    height: 200px;
    overflow: hidden;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
    text-align:center;
    line-height:160px;

}
.header.shrink {
    height: 100px;
    line-height:80px;
}
.header h1
{
    font-size:30px;
    font-weight:normal;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.header.shrink h1
{
    font-size:24px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
} */




.header {
  /* position: fixed !important; */
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  /* color: #fff; */
  z-index: 1000;
  height: 70px;
  /* overflow: hidden; */
  transition: height 0.3s;
  text-align: center;
  line-height: 70px;
}

.header.shrink {
  height: 50px;
  line-height: 50px;
  background:white;
  box-shadow: 0 5px 16px #0000004a;
}

.header.shrink .navbar-brand img {
  width: 150px !important;
  transition: width 0.3s;
  transition: all 0.3s;
}

.header.shrink .click-dropdown span {
  font-size:14px;
  font-weight:500;
  transition: all 0.3s;
}


.header.shrink .click-dropdown i{
  font-size: 14px;
  transition: all 0.3s;
}








/* .content
{
height:2000px;
} */


.readOnly {
    pointer-events: none; 
    user-select: none; 
}


.dropdown-item{
  line-height: normal !important;
}






@media only screen and (max-width:1200px) {
  .currency-text {
    display: none;
  }
}

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;
}

@media only screen and (min-width:992px) {
  .contact-section-mobile {
    display: none !important;
  }

  .contact-section-large {
    display: flex !important;
  }

  .contact-section-collapse {
    display: none !important;
  }
}




@media only screen and (max-width:991px) {
  .contact-section-mobile {
    display: flex !important;
  }

  .contact-section-large {
    display: none !important;
  }

  .contact-section-collapse {
    display: block !important;
  }
}




@media screen and (max-width: 767px){
    #navbarSupportedContent {
        border-radius: 10px;
        box-shadow: 0px 0px 6px -1px black;
    }
}







@media only screen and (max-width:480px) {
  .myBooking{
    display: none !important;
  }

  .portal_logo{
  width: 150px;
  height: 55px;
  cursor: pointer; 
}

.dropdown-toggle {
  width: 40px;
    height: 28px;
    padding: 0 !important;
}

#navbarSupportedContent .navbar-nav{
  align-items:flex-start !important;
}

.header {
  line-height: 0 !important;
}

}
</style>
