<template>


  <div class="bg-white py-3">
    <div class="container pageSection" style="max-width:1000px;">
      <div class="row gx-0 align-end py-3 border-4 border-bottom border-dark">
        <div class="col-6">
          <div>
            <p class="f-size-14">Problem seeing this email? <a href="" class="text-decoration-none f-size-14 fw-400">
                Click here</a></p>
            <img :src="portalLogo" style="width:200px;" />
            <p class="f-size-12 mt-1">us.traveljunvtion.com / contact</p>
          </div>
        </div>
        <div class="col-6">
          <div class="text-end">
            <h4 class="fw-600">TRAVEL DOCUMENT</h4>
            <p class="f-size-14"><span>Order no: </span><span>45353535353</span></p>
            <p class="f-size-14"><span>Issued: </span><span>2024-04-12</span></p>
          </div>
        </div>
      </div>


      <div class="row gx-0 py-3 border-4 border-bottom">
        <div class="col-md-12">
          <div class="d-flex">
            <div>
              <v-img src="../assets/flightIcon.png" width="30px" style="transform: rotate(45deg);margin:auto;
                    filter: brightness(1.5) invert(1);"></v-img>
            </div>
            <h4 class="fw-600 ms-3"><span>Toronto</span>-<span>Chennai</span></h4>
          </div>
        </div>
      </div>

      <div class="row gx-0 py-3 border-4 border-bottom">
        <div class="col-2">
          <h4 class="fw-600 theme-text-accent-one">1</h4>
        </div>
        <div class="col-10">
          <div class="row gx-0">
            <div class="col-6">
              <p>Your Airline check-in reference(s)</p>
            </div>
            <div class="col-6">
              <span class="py-2 px-3 bg-light f-size-22 fw-500">ADE-45D834SW</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row gx-0 py-3">
        <div class="col-2">
          <h4 class="fw-600 theme-text-accent-one">2</h4>
        </div>
        <div class="col-10">
          <div class="row gx-0">
            <div class="col-6">
              <p>Your Airline check-in reference(s)</p>
            </div>
            <div class="col-6">
              <p>ADE-45D834SW</p>
            </div>
          </div>
        </div>
      </div>


      <div class="border-4 border-top border-dark py-2">
        <p class="f-size-14 px-2 py-3 mt-2 bg-light"><span class="fw-600">IMPORTANT: </span>
          <span class="fw-400">PRINT OUT THIS TRAVEL DOCUMENT AND BRING IT WITH YOU ON YOUR TRIP ALONG WITH YOUR VALID
            PASSPORT</span>
        </p>

        <p class="f-size-14 fw-500 mt-2">Flight durations are preliminary and can be changed at short notice.
          It is the traveler's responsibility to keep updated with carrier
          changes via www.checkmytrip.com or directly via the carrier.</p>

        <p class="f-size-14 fw-500 mt-2">If you have a return ticket it is not possible to not use the first part and
          only use the return.
          Flight tickets must be used in chronological order, otherwise the carrier will cancel the remaining part of
          the trip.</p>
      </div>

      <div class="row gx-0 py-3 border-5 border-top border-dark">
        <div class="col-md-2 col-3">
          <h5 class="mb-2 silverColor">Flight</h5>

          <div class="mb-2">
            <h5 class="fw-600">CX 865</h5>
            <p class="f-size-12 fw-500">Cathay Pacific Airways</p>
          </div>

          <div>
            <p class="f-size-12 fw-500 text-gray">Operated by:</p>
            <p class="f-size-12 fw-500">Cathay Pacific Airways</p>
          </div>
        </div>


        <div class="col-md-10 col-9 border-2 border-start border-dark px-2">
          <div class="row gx-0">
            <div class="col-5">
              <h5 class="mb-2 silverColor">Departure</h5>
              <p class="f-size-14 fw-500">YVR</p>
              <h5 class="fw-600">Toronto</h5>
              <p class="f-size-14 fw-500">Vancouver International Apt Canada</p>
              <div class="px-3 py-2 bg-muted" style="width:max-content;">
                <p class="f-size-14 fw-500">TUE 7 MAY</p>
                <h5 class="fw-600">00:55</h5>
              </div>
            </div>
            <div class="col-2 m-auto">
              <v-img src="../assets/flightIcon.png" width="25px" style="transform: rotate(45deg);margin:auto;
                    filter: brightness(1.5) invert(1);"></v-img>
            </div>
            <div class="col-5 text-end">
              <h5 class="mb-2 silverColor">Arrival</h5>
              <p class="f-size-14 fw-500">MAA</p>
              <h5 class="fw-600">Chennai</h5>
              <p class="f-size-14 fw-500">Chennai Internation Airport</p>
              <div class="px-3 py-2 bg-muted float-right" style="width:max-content;">
                <p class="f-size-14 fw-500">MON 25 SEP</p>
                <h5 class="fw-600">23:55</h5>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 border-2 border-top border-dark mt-2">
          <div class="border d-inline-block theme-border-radius font-small p-2 mt-2">
            Change planes at YVR, Connecting Time: <span class="fw-bold">14:08</span> Connecting
            flight may depart from a different terminal</div>
        </div>
      </div>



      <div class="mt-2">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col" class="silverColor">Passanger Name</th>
              <th scope="col" class="silverColor">Class</th>
              <th scope="col" class="silverColor">Baggage</th>
              <th scope="col" class="silverColor">e-Ticket no.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mark</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <td>Jacob</td>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <td>Jacob</td>
              <td>Larry the Bird</td>
              <td>@twitter</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>







    </div>
  </div>













  <div class="pagewrap modify-search invisible" v-if="showConfirmPage">
    <!-- body section -->
    <div class="content-section">
      <!-- Booking Confirmation Section -->
      <div class="review-flight py-5">
        <div class="container">
          <!-- flight confirmation section -->
          <div class="theme-box-shadow theme-border-radius bg-light mb-3">
            <div class="row py-4 m-auto">
              <div class="col-12 col-md-8">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div class="p-2 border border-success rounded-circle lh-1">
                      <i class="bi bi-check-lg fs-2 text-success"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <span class="fs-4 fw-bold">Your Booking is Confirmed!</span>
                    <p class="mb-0 font-medium">
                      Thank you for booking with Flight World. You will soon
                      receive a confirmation via email.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 text-end">
                <div class="font-medium fw-bold border p-2 d-inline-block theme-border-radius">
                  Booking ID: <a href="#" class="text-success">{{ tripId }}</a>
                </div>
                <div class="font-medium mt-3">
                  <span class="fw-bold">Booked on:</span>
                  {{ bookingTime }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-2">
            <v-img :src="portalLogo" style="max-width: 140px; cursor: pointer" />
          </div>
          <v-card class="p-2" style="background: #f8f9fa">
            <div v-if="rount || oneway" class="p-3 bookingInfoCard" style="border: 1px solid grey; border-radius: 5px">
              <div class="row align-center">
                <div class="col-md-2 col-sm-1 col-2 my-md-0 my-2 bookTripId-info" style="width: 20%">
                  <p class="m-0 f-size-13 fw-500">Trip ID #</p>
                  <span class="f-size-12">{{ tripId }}</span>
                </div>

                <div class="col-md-5 col-sm-6 col-4 justify-center my-md-0 my-2 bookPlace-info" style="width: 35%">
                  <div class="row">
                    <div class="col-6">
                      <span class="f-size-13 fw-500">{{
                        getdepcode(depatureData)
                      }}</span>
                      <br />
                      <span class="f-size-12">{{
                        getstarttime(depatureData)
                      }}</span>
                    </div>
                    <div class="col-6">
                      <span class="f-size-13 fw-500">{{
                        getarrcode(depatureData)
                      }}</span>
                      <br />
                      <span class="f-size-12">{{
                        rount
                          ? getstarttime1(arrivalData)
                          : getOnewaystarttime1(depatureData)
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-3 my-md-0 my-2 bookDate-info" style="width: 23%">
                  <p class="m-0 f-size-13 fw-500">Booking Date</p>
                  <span class="f-size-12">{{ bookingTime }}</span>
                </div>

                <div class="col-md-2 col-sm-2 col-5 justify-center my-md-0 my-2 bookTrip-info" style="width: 22%">
                  <div>
                    <!-- <span style="margin-right: 10px">&#9679;</span> -->
                    <p class="m-0 f-size-13 fw-500">&#9679; {{ triptype }}</p>
                    <p class="m-0 f-size-13 fw-500" v-if="searchcabintype" style="
                          text-transform: uppercase;
                          position: relative;
                          top: 1;
                        ">
                      &#9679; {{ searchcabintype }}
                    </p>
                  </div>

                  <!-- <div class="d-flex align-center" v-if="searchcabintype">
                      <span style="margin-right: 10px">&#9679;</span>
                      
                    </div> -->
                </div>
              </div>
            </div>

            <div v-if="multi" style="border: 1px solid grey; border-radius: 5px; padding: 5px">
              <div class="row align-center">
                <div class="col-md-2 col-sm-1 col-2 my-md-0 my-2" style="width: 20%">
                  <p class="m-0 f-size-13 fw-500">Trip ID #</p>
                  <span class="f-size-12">{{ tripId }}</span>
                </div>

                <div class="col-md-5 col-sm-6 col-4 justify-center my-md-0 my-2" style="width: 35%">
                  <div class="row" v-if="Flight1.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight1) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight1) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight1) }}
                      </span>
                    </div>
                  </div>

                  <div class="row" v-if="Flight2.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight2) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight2) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight2) }}
                      </span>
                    </div>
                  </div>

                  <div class="row" v-if="Flight3.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight3) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight3) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight3) }}
                      </span>
                    </div>
                  </div>

                  <div class="row" v-if="Flight4.length > 0">
                    <div class="col-12">
                      <span class="f-size-13 fw-500">
                        {{ multigetdepcode(Flight4) }}
                      </span>
                      <span class="f-size-13 fw-500">
                        - {{ multigetarrcode(Flight4) }}
                      </span>
                      <span class="f-size-12">
                        - {{ multigetdeptime(Flight4) }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-3 my-md-0 my-2" style="width: 23%">
                  <p class="m-0 f-size-13 fw-500">Booking Date</p>
                  <span class="f-size-12">{{ bookingTime }}</span>
                </div>

                <div class="col-md-2 col-sm-2 col-5 justify-center my-md-0 my-2" style="width: 22%">
                  <div>
                    <!-- <span style="margin-right: 10px">&#9679;</span> -->
                    <p class="m-0 f-size-13 fw-500">&#9679; {{ triptype }}</p>
                    <p class="m-0 f-size-13 fw-500" v-if="searchcabintype" style="
                          text-transform: uppercase;
                          position: relative;
                          top: 1;
                        ">
                      &#9679; {{ searchcabintype }}
                    </p>
                  </div>
                  <!-- <div class="d-flex align-center" v-if="searchcabintype">
                      <span style="margin-right: 10px">&#9679;</span>
                      
                    </div> -->
                </div>
              </div>
            </div>

          </v-card>

          <div class="row mt-3">
            <div class="col-12 col-md-9">
              <div class="d-flex justify-content-between align-items-start mb-3">
                <span class="fw-bold">Booking Details</span>
              </div>
              <!-- flight details section -->
              <div class="mb-3 px-3">
                <!-- ----multicity--------- -->

                <div v-if="Flight1.length > 0">
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div class="col-2 d-flex align-items-center" style="width: 10.6%">
                            <v-img :src="Flight1[0].logo" width="40px"></v-img>
                          </div>
                          <div class="col-10 ps-0">
                            <div class="font-medium">
                              Flight1
                              <span class="header-con">
                                {{ Flight1[0].departure_city_name }} -
                                {{
                                  Flight1[Flight1.length - 1].arrival_city_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart1"
                          role="button" aria-expanded="false" aria-controls="collapseDepart1">Flight details<i
                            class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart1">
                      <div class="row" v-for="(data, index) of Flight1" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width: 15%">
                                  <div class="font-medium">
                                    {{ data.flightname }}
                                  </div>
                                  <div class="font-small">
                                    {{ data.flight_number }}
                                  </div>
                                </div>
                                <!-- <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS
                                </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.cabinClass }}
                                  </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.Refund }}
                                  </div> -->
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.depart_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getdeptime1(data.depart_datetime) }} |
                                  <span class="fw-normal">{{
                                    getdepdate1(data.depart_datetime)
                                  }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.depAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start">
                                  <i class="bi bi-clock pe-2 f-size-12"></i>
                                </div>
                                <div class="float-start d-flex justify-center flex-column">
                                  <span class="font-medium d-block f-size-12">
                                    {{ flighttimeData(data) }} hour(s)
                                    {{ flighttimeData1(data) }}
                                    minute(s)
                                  </span>
                                  <!-- <span class="font-small d-block"
                                      >{{
                                        data.Flight1JourneyTime.Stops
                                      }}
                                      Stops</span
                                    > -->
                                  <!-- <span class="font-small d-block"
                                      >Equipment:{{
                                        data1.Equipment.AircraftCode
                                      }}</span
                                    > -->
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.arrival_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getarrtime(data.arrival_datetime) }} |
                                  <span class="fw-normal">
                                    {{
                                      getarrdate(data.arrival_datetime)
                                    }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.arrAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <span class="font-small text-uppercase fw-bold">
                                  <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon>
                                  Baggage
                                </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== Flight1.length - 1">
                                  Layover Time:{{ data.overlayTime }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                </div>

                <div v-if="Flight2.length > 0">
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div class="col-2 d-flex align-items-center" style="width: 10.6%">
                            <v-img :src="Flight2[0].logo" width="40px"></v-img>
                          </div>
                          <div class="col-10 ps-0">
                            <div class="font-medium">
                              Flight2
                              <span class="header-con">
                                {{ Flight2[0].departure_city_name }} -
                                {{
                                  Flight2[Flight2.length - 1].arrival_city_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart2"
                          role="button" aria-expanded="false" aria-controls="collapseDepart2">Flight details<i
                            class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart2">
                      <div class="row" v-for="(data, index) of Flight2" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width: 15%">
                                  <div class="font-medium">
                                    {{ data.flightname }}
                                  </div>
                                  <div class="font-small">
                                    {{ data.flight_number }}
                                  </div>
                                </div>
                                <!-- <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS
                                </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.cabinClass }}
                                  </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.Refund }}
                                  </div> -->
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.depart_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getdeptime1(data.depart_datetime) }} |
                                  <span class="fw-normal">{{
                                    getdepdate1(data.depart_datetime)
                                  }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.depAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start">
                                  <i class="bi bi-clock pe-2 f-size-12"></i>
                                </div>
                                <div class="float-start d-flex justify-center flex-column">
                                  <span class="font-medium d-block f-size-12">
                                    {{ flighttimeData(data) }} hour(s)
                                    {{ flighttimeData1(data) }}
                                    minute(s)
                                  </span>
                                  <!-- <span class="font-small d-block"
                                      >{{
                                        data.Flight1JourneyTime.Stops
                                      }}
                                      Stops</span
                                    > -->
                                  <!-- <span class="font-small d-block"
                                      >Equipment:{{
                                        data1.Equipment.AircraftCode
                                      }}</span
                                    > -->
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.arrival_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getarrtime(data.arrival_datetime) }} |
                                  <span class="fw-normal">
                                    {{
                                      getarrdate(data.arrival_datetime)
                                    }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.arrAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <span class="font-small text-uppercase fw-bold">
                                  <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon>
                                  Baggage
                                </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== Flight2.length - 1">
                                  Layover Time:{{ data.overlayTime }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                </div>

                <div v-if="Flight3.length > 0">
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div class="col-2 d-flex align-items-center" style="width: 10.6%">
                            <v-img :src="Flight3[0].logo" width="40px"></v-img>
                          </div>
                          <div class="col-10 ps-0">
                            <div class="font-medium">
                              Flight2
                              <span class="header-con">
                                {{ Flight3[0].departure_city_name }} -
                                {{
                                  Flight3[Flight3.length - 1].arrival_city_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart3"
                          role="button" aria-expanded="false" aria-controls="collapseDepart3">Flight details<i
                            class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart3">
                      <div class="row" v-for="(data, index) of Flight3" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width: 15%">
                                  <div class="font-medium">
                                    {{ data.flightname }}
                                  </div>
                                  <div class="font-small">
                                    {{ data.flight_number }}
                                  </div>
                                </div>
                                <!-- <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS
                                </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.cabinClass }}
                                  </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.Refund }}
                                  </div> -->
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.depart_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getdeptime1(data.depart_datetime) }} |
                                  <span class="fw-normal">{{
                                    getdepdate1(data.depart_datetime)
                                  }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.depAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start">
                                  <i class="bi bi-clock pe-2 f-size-12"></i>
                                </div>
                                <div class="float-start d-flex justify-center flex-column">
                                  <span class="font-medium d-block f-size-12">
                                    {{ flighttimeData(data) }} hour(s)
                                    {{ flighttimeData1(data) }}
                                    minute(s)
                                  </span>
                                  <!-- <span class="font-small d-block"
                                      >{{
                                        data.Flight1JourneyTime.Stops
                                      }}
                                      Stops</span
                                    > -->
                                  <!-- <span class="font-small d-block"
                                      >Equipment:{{
                                        data1.Equipment.AircraftCode
                                      }}</span
                                    > -->
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.arrival_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getarrtime(data.arrival_datetime) }} |
                                  <span class="fw-normal">
                                    {{
                                      getarrdate(data.arrival_datetime)
                                    }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.arrAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <span class="font-small text-uppercase fw-bold">
                                  <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon>
                                  Baggage
                                </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== Flight3.length - 1">
                                  Layover Time:{{ data.overlayTime }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                </div>

                <div v-if="Flight4.length > 0">
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div class="col-2 d-flex align-items-center" style="width: 10.6%">
                            <v-img :src="Flight2[0].logo" width="40px"></v-img>
                          </div>
                          <div class="col-10 ps-0">
                            <div class="font-medium">
                              Flight4
                              <span class="header-con">
                                {{ Flight4[0].departure_city_name }} -
                                {{
                                  Flight4[Flight4.length - 1].arrival_city_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart4"
                          role="button" aria-expanded="false" aria-controls="collapseDepart4">Flight details<i
                            class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart4">
                      <div class="row" v-for="(data, index) of Flight4" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width: 15%">
                                  <div class="font-medium">
                                    {{ data.flightname }}
                                  </div>
                                  <div class="font-small">
                                    {{ data.flight_number }}
                                  </div>
                                </div>
                                <!-- <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS
                                </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.cabinClass }}
                                  </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.Refund }}
                                  </div> -->
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.depart_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getdeptime1(data.depart_datetime) }} |
                                  <span class="fw-normal">{{
                                    getdepdate1(data.depart_datetime)
                                  }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.depAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start">
                                  <i class="bi bi-clock pe-2 f-size-12"></i>
                                </div>
                                <div class="float-start d-flex justify-center flex-column">
                                  <span class="font-medium d-block f-size-12">
                                    {{ flighttimeData(data) }} hour(s)
                                    {{ flighttimeData1(data) }}
                                    minute(s)
                                  </span>
                                  <!-- <span class="font-small d-block"
                                      >{{
                                        data.Flight1JourneyTime.Stops
                                      }}
                                      Stops</span
                                    > -->
                                  <!-- <span class="font-small d-block"
                                      >Equipment:{{
                                        data1.Equipment.AircraftCode
                                      }}</span
                                    > -->
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.arrival_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getarrtime(data.arrival_datetime) }} |
                                  <span class="fw-normal">
                                    {{
                                      getarrdate(data.arrival_datetime)
                                    }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.arrAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <span class="font-small text-uppercase fw-bold">
                                  <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon>
                                  Baggage
                                </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== Flight4.length - 1">
                                  Layover Time:{{ data.overlayTime }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                </div>

                <!-- ------oneway------ -->
                <div v-if="depatureData.length > 0">
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div class="col-2 d-flex align-items-center" style="width: 10.6%">
                            <v-img :src="depatureData[0].logo" width="40px"></v-img>
                          </div>
                          <div class="col-10 ps-0">
                            <div class="font-medium">
                              Depature
                              <span class="header-con">
                                {{ depatureData[0].departure_city_name }} -
                                {{
                                  depatureData[depatureData.length - 1]
                                    .arrival_city_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart"
                          role="button" aria-expanded="false" aria-controls="collapseDepart">Flight details<i
                            class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart">
                      <div class="row" v-for="(data, index) of depatureData" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width: 15%">
                                  <div class="font-medium">
                                    {{ data.flightname }}
                                  </div>
                                  <div class="font-small">
                                    {{ data.flight_number }}
                                  </div>
                                </div>
                                <!-- <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS
                                </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.cabinClass }}
                                  </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.Refund }}
                                  </div> -->
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.depart_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getdeptime1(data.depart_datetime) }} |
                                  <span class="fw-normal">{{
                                    getdepdate1(data.depart_datetime)
                                  }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.depAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start">
                                  <i class="bi bi-clock pe-2 f-size-12"></i>
                                </div>
                                <div class="float-start d-flex justify-center flex-column">
                                  <span class="font-medium d-block f-size-12">
                                    {{ flighttimeData(data) }} hour(s)
                                    {{ flighttimeData1(data) }}
                                    minute(s)
                                  </span>
                                  <!-- <span class="font-small d-block"
                                      >{{
                                        data.Flight1JourneyTime.Stops
                                      }}
                                      Stops</span
                                    > -->
                                  <!-- <span class="font-small d-block"
                                      >Equipment:{{
                                        data1.Equipment.AircraftCode
                                      }}</span
                                    > -->
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.arrival_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getarrtime(data.arrival_datetime) }} |
                                  <span class="fw-normal">
                                    {{
                                      getarrdate(data.arrival_datetime)
                                    }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.arrAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <span class="font-small text-uppercase fw-bold">
                                  <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon>
                                  Baggage
                                </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== depatureData.length - 1">
                                  Layover Time:{{ data.overlayTime }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                </div>

                <div v-if="arrivalData.length > 0">
                  <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                    <div class="row border-bottom py-2 m-auto">
                      <div class="col-8">
                        <div class="row d-flex flex-row">
                          <div class="col-2 d-flex align-items-center" style="width: 10.6%">
                            <v-img :src="arrivalData[0].logo" width="40px"></v-img>
                          </div>
                          <div class="col-10 ps-0">
                            <div class="font-medium">
                              Return
                              <span class="header-con">
                                {{ arrivalData[0].departure_city_name }} -
                                {{
                                  arrivalData[arrivalData.length - 1]
                                    .arrival_city_name
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-end align-self-center">
                        <a class="font-small theme-text-accent-one" data-bs-toggle="collapse" href="#collapseDepart2"
                          role="button" aria-expanded="false" aria-controls="collapseDepart2">Flight details<i
                            class="bi bi-chevron-down ps-1"></i></a>
                      </div>
                    </div>
                    <div class="collapse" id="collapseDepart2">
                      <div class="row" v-for="(data, index) of arrivalData" :key="index">
                        <div class="col-sm-12">
                          <div class="px-3">
                            <ul class="row py-3">
                              <li class="col-12 pb-3">
                                <div class="float-start">
                                  <v-img :src="data.logo" width="30px"></v-img>
                                </div>
                                <div class="float-start ms-2 d-flex flex-column" style="width: 15%">
                                  <div class="font-medium">
                                    {{ data.flightname }}
                                  </div>
                                  <div class="font-small">
                                    {{ data.flight_number }}
                                  </div>
                                </div>
                                <!-- <div
                                  class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                  Class - SS
                                </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.cabinClass }}
                                  </div> -->
                                <!-- <div class="font-medium float-start mx-5">
                                    {{ data.Refund }}
                                  </div> -->
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.depart_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getdeptime1(data.depart_datetime) }} |
                                  <span class="fw-normal">{{
                                    getdepdate1(data.depart_datetime)
                                  }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.depAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="float-start">
                                  <i class="bi bi-clock pe-2 f-size-12"></i>
                                </div>
                                <div class="float-start d-flex justify-center flex-column">
                                  <span class="font-medium d-block f-size-12">
                                    {{ flighttimeData(data) }} hour(s)
                                    {{ flighttimeData1(data) }}
                                    minute(s)
                                  </span>
                                  <!-- <span class="font-small d-block"
                                      >{{
                                        data.Flight1JourneyTime.Stops
                                      }}
                                      Stops</span
                                    > -->
                                  <!-- <span class="font-small d-block"
                                      >Equipment:{{
                                        data1.Equipment.AircraftCode
                                      }}</span
                                    > -->
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <div class="font-medium fw-bold text-uppercase">
                                  {{ data.arrival_airport }}
                                </div>
                                <div class="font-medium fw-bold">
                                  {{ getarrtime(data.arrival_datetime) }} |
                                  <span class="fw-normal">
                                    {{
                                      getarrdate(data.arrival_datetime)
                                    }}</span>
                                </div>
                                <div class="font-small">
                                  {{ data.arrAirportName }}
                                </div>
                              </li>
                              <li class="col-12 col-md-6 col-lg-3 pb-3">
                                <span class="font-small text-uppercase fw-bold">
                                  <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon>
                                  Baggage
                                </span>
                                <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                  class="font-small d-block">CABIN : 7 Kgs</span>
                              </li>
                              <li class="col-12 text-center">
                                <div class="border d-inline-block theme-border-radius font-small p-2"
                                  v-if="index !== arrivalData.length - 1">
                                  Layover Time:{{ data.overlayTime }}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- flight details section -->
                </div>
              </div>
              <!-- traveller details section -->
              <div class="row">
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <!-- travel details -->
                  <div class="theme-box-shadow theme-border-radius bg-light p-3">
                    <div class="d-flex mb-3">
                      <span class="fw-bold font-medium">Traveller Details</span>
                    </div>
                    <div class="col-12" v-for="(datas1, index) of passengerDetail" :key="index">
                      <div class="d-flex justify-content-between mb-2">
                        <span class="font-medium">{{ index + 1 }}. {{ datas1.salutation }}.
                          {{ datas1.firstname }} {{ datas1.middlename }}
                          {{ datas1.lastname }}</span>
                        <span class="font-medium"> {{ datas1.pax_type }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <!-- contact details -->
                  <div class="theme-box-shadow theme-border-radius bg-light p-3">
                    <div class="d-flex mb-3">
                      <span class="fw-bold font-medium">Contact Details</span>
                    </div>
                    <div class="col-12">
                      <div class="d-flex mb-3">
                        <span class="font-medium">
                          <i class="bi bi-envelope fs-6 me-2"></i>
                          {{ passEmail }}
                        </span>
                      </div>
                      <div class="d-flex mb-3">
                        <span class="font-medium">
                          <i class="bi bi-telephone fs-6 me-2"></i>({{
                            countrycode
                          }}) {{ contactnum }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="fw-bold mb-3">Fare Details</div>
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="theme-box-shadow theme-border-radius bg-light">
                    <ul class="p-0">
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex justify-content-between">
                          <span class="font-medium" style="font-weight: 500">
                            Passenger</span>
                          <!-- <span>
                                                          <span class="" style="font-weight:500;">Total Price</span>
                                                      </span> -->
                        </div>
                      </li>
                      <!-- gst charge fare -->
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex flex-column">
                          <div v-if="adult > 0">{{ adult }} x Adult</div>
                          <div v-if="child > 0">{{ child }} x Child</div>
                          <div v-if="infant > 0">{{ infant }} x Lap Infant</div>
                          <!-- </span> -->
                        </div>
                      </li>
                      <!-- grand total charge fare -->
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex justify-content-between">
                          <span class="fs-6" style="font-weight: 600">
                            Grand Total</span>
                          <span class="fs-6">
                            <span class="fw-bold">
                              {{ currency }}
                              {{ totalprice }}</span>
                          </span>
                        </div>
                      </li>
                      <li class="border-bottom p-3 font-medium">
                        <!-- <a
                            class="d-flex justify-content-between theme-text-accent-one"
                            data-bs-toggle="collapse"
                            href="#faresummary"
                            role="button"
                            aria-expanded="false"
                            aria-controls="faresummary"
                          >
                            <span
                              class="font-medium"
                              style="
                                font-weight: 600;
                                color: rgb(13, 110, 253);
                                text-decoration: underline;
                              "
                            >
                              Fare Summary<i
                                class="bi bi-plus-circle-fill ps-2"
                              ></i
                            ></span>
                          </a> -->
                        <a class="d-flex justify-content-between theme-text-accent-one" data-bs-toggle="collapse"
                          href="#faresummary" role="button" aria-expanded="false" aria-controls="faresummary">
                          <span class="font-medium fw-bold"> Fare Summary</span>
                          <span>
                            <i class="bi bi-plus-circle-fill theme-text-accent-two"></i>
                          </span>
                        </a>
                        <div class="collapse" id="faresummary">
                          <div class="d-flex justify-content-between pt-3">
                            <span class="font-medium">Base Price<br />
                              Taxes & Fees</span>
                            <span class="font-medium text-end">
                              {{ currency }} {{ baseprice }}<br />
                              {{ currency }} {{ totalTax }}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="theme-box-shadow theme-border-radius bg-light p-3 font-small">
                    Cancellation &amp; Date change charges
                    <a href="#" class="">More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- button section -->
        </div>
      </div>

      <!-- modal box for skip payment-->
      <!-- <div class="modal fade show" :class="dialog3 ? 'show' : ''" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" 
        aria-hidden="true" :role="dialog3 ? 'dialog' : ''" style="display:block !important;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Are you sure ?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="p-5 pb-0 text-center">
                        <p class="font-medium">By clicking on Okay, all your seat, meal and
                            extra baggage selections will be discarded.</p>
                    </div>
                    <div class="modal-footer border-0 justify-content-center pb-5">
                        <button type="button" class="btn btn-effect btn-book" data-bs-dismiss="modal">Yes
                            Sure</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <!-- page footer section -->
  </div>
  <!-- <div v-if="overlay">
      <dataloader></dataloader>
    </div> -->




  <v-dialog v-model="dialog3" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">Invalid PNR</h3>
        <!-- <h5>Your request cannot be processed at this moment.</h5>
          <h6>Please contact customer support.</h6> -->
        <!-- <h5 class="text-center">Invalid PNR</h5> -->
        <h6 class="text-center">Please try again</h6>
        <div class="d-flex mt-3">
          <!-- <v-btn @click="tryAgain1()" color="#002d5b">Try Again</v-btn> -->
          <button type="button" @click="tryAgain1()" class="btn btn-effect btn-book">Try Again</button>
          <button type="button" @click="goToHome()" class="btn btn-effect btn-book ms-2">Home</button>

          <!-- <v-btn class="ms-2" to="/" color="#002d5b">Return to Home</v-btn> -->
        </div>
      </v-card>
    </div>
  </v-dialog>

  <pageLoader v-if="pageloader" :style="{ 'z-index': pageloader ? '999' : '' }"></pageLoader>
</template>

<script>
import moment from "moment";
import airLogo from "@/airlinesLogo.json";
// import dataloader from "@/components/dataLoder.vue";
import userData from "@/airport_icao.json";
import axios from "axios";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import pageLoader from "@/components/pageLoader.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    // VueDatePicker,
    // dataloader,
    pageLoader,
  },
  data() {
    return {
      bookid: "",
      lastname: "",

      pageloader: true,
      showConfirmPage: false,
      datavkeer: true,
      dialog: false,
      // valid: false,
      viewbook: true,
      dateFormat: "dd MMM yyyy",
      overlay: false,
      mybooking: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      bookstatus: "Cancel Booking",
      requstup: false,
      datechange: false,
      dedate1: "",
      dedate2: "",
      dedate3: "",
      dedate4: "",
      retriveData: [],
      itineryData: [],
      searchcabintype: "",
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      totalTax: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      adult: 0,
      child: 0,
      infant: 0,
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      mobilebtn: true,
      contactnum: "",
      passEmail: "",
      bookingStatus: "",
      bookingTime: "",
      countrycode: "",
      Flight1: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      multicity: [],
      Eticketno: false,
      rount: false,
      oneway: false,
      multi: false,

      managePopup: false,
      modifyCheckBox: false,

      validForm: false,

      portal_sitetitle: "",
      getPassid: "",
      retreiveBookApi: "",
      portalId: "",
      portalLogo: "",

      btnActive: true,

      emailPortal: "",

      errorMsg: false,
    };
  },

  // computed: {
  //   window.history.forward();
  //       function noBack() {
  //           window.history.forward();
  //       }
  // },

  methods: {
    goToHome() {
      this.$router.push('/');
    },
    tryAgain1() {
      this.dialog3 = false;
      this.$router.push("/mybooking");
      // this.retrivingBooking();
    },

    toggleTawkToChat() {
      if (this.validForm && this.modifyCheckBox) {
        this.managePopup = false;
        if (window.Tawk_API) {
          window.Tawk_API.toggle();
        }
      } else {
        this.errorMsg = true;
        if (!this.validForm) {
          return this.errorMsg;
        }
      }
    },

    seleceprint1() {
      const printContent = document.getElementById("printMe");
      if (printContent) {
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContent.innerHTML;
        window.print();
        document.body.innerHTML = originalContents;
      } else {
        console.error("Element with ID 'printMe' not found..1");
      }
    },

    multigetdepcode(data) {
      console.log(data, "ppppp");
      return data[0].departure_city_name;
    },
    multigetarrcode(data) {
      return data[data.length - 1].departure_city_name;
    },
    multigetdeptime(data) {
      return moment(data[0].depart_datetime).format("DD-MMM-YYYY");
    },

    modify(data) {
      this.dialog1 = true;

      if (data == "cancel") {
        this.bookstatus = "Cancel Booking";
      } else {
        this.bookstatus = "Date Change";
      }
    },

    modifyreq(data) {
      console.log(data, "ppppp");
      this.overlay = true;
      let result = "";
      if (data == "Cancel Booking") {
        result = "cancel";
      } else if (data == "Date Change") {
        result = "date_change";
      } else if (data == "Upgrade Booking") {
        result = "upgrade";
      } else if (data == "Request Addons") {
        result = "addon";
      }

      let modify = {
        request_type: "modify_request",
        booking_id: this.tripId,
        modify_request_type: result,
        outbound_date_between: "",
        inbound_date_between: "",
        notes: "test",
      };

      modify.outbound_date_between = `${moment(this.dedate1).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate2).format("YYYY-MM-DD")}`;
      modify.inbound_date_between = `${moment(this.dedate3).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate4).format("YYYY-MM-DD")}`;

      console.log(modify, "modifymodify");
      // console.log(this.modifyBookingUrl,'this.modifyBookingUrl........')

      axios
        .post(this.modifyBookingUrl, modify, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response, "response99999999999999999999999999");
          if (response.data.modify_response == "success") {
            setTimeout(() => {
              this.overlay = false;
              this.dialog1 = false;
              this.dialog2 = true;
            }, 2000);
          } else {
            this.dialog3 = true;
            this.overlay = false;
          }
          console.log(response.data.modify_response, "success");
        })
        .catch((error) => {
          setTimeout(() => {
            this.dialog3 = true;
            this.overlay = false;
          }, 2000);

          console.log(error, "modify");
        });
    },

    getdob($event) {
      let birthDate = moment($event).format("DD-MMM-YYYY");
      let currentDate = moment();
      let age = currentDate.diff(birthDate, "years");

      console.log("Age:", age);
      return age;
    },
    getdepdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("ddd MMMM D, YYYY");
    },
    getarrtime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode(data) {
      console.log(data, "pppp");
      return data[0].departure_city_name;
    },
    getarrcode(data) {
      console.log(data, "pppp");
      return data[data.length - 1].arrival_city_name;
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getdepdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("ddd MMMM D, YYYY");
    },
    getdeptime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    // getdepcode1(data) {
    //   console.log(data, "dataaaaaaaaaaaaaaaa");
    //   if (data) {
    //     return data[0].depart_airport;
    //   }
    // },
    // getarrcode1(data) {
    //   if (data) {
    //     return data[data.length - 1].arrival_airport;
    //   }
    // },

    getstarttime1(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getOnewaystarttime1(data) {
      let depdata = data[data.length - 1].arrival_datetime.split("T");
      console.log(data, "depdatadepdata....depdatadepdata");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    capitalizeLastName() {
      this.lastname =
        this.lastname.charAt(0).toUpperCase() + this.lastname.slice(1);
    },

    bookingretrive() {
      this.airlogodata = airLogo;
      // setTimeout(() => {
      //   this.overlay = false;
      //   this.showConfirmPage = true;
      // }, 5000);
      if (this.retriveData.length > 0) {
        //      this.arrivalData = [];
        // this.depatureData = [];
        this.retriveData.forEach((v) => {
          console.log(v, "dtaswwwdjffjkfjkadfkfadjkdjkadjk");
          this.baseprice = parseFloat(v.fare_info.itin_base_fare).toFixed(2);
          this.totalTax = parseFloat(v.fare_info.itin_total_tax).toFixed(2);
          if (v.fare_info.itin_currency == "USD") {
            this.currency = "US$";
          } else if (v.fare_info.itin_currency == "CAD") {
            this.currency = "CA$";
          } else {
            this.currency = v.fare_info.itin_currency;
          }
          this.totalprice = parseFloat(v.fare_info.itin_total_fare).toFixed(2);
          this.passengerDetail = v.pax_info;
          this.passengerDetail.forEach((c) => {
            if (c.pax_type == "Adult") {
              this.adult += 1;
            } else if (c.pax_type == "Child") {
              this.child += 1;
            } else if (c.pax_type == "Infant") {
              this.infant += 1;
            }
          });
          this.itineryData = v.itinerary;
          this.contactnum = v.contact_info.paxphone;
          this.passEmail = v.contact_info.paxemail;
          this.tripId = v.booking_id;
          this.bookingStatus = v.status;
          if (this.bookingStatus == "ticketed") {
            this.Eticketno = true;
          } else {
            this.Eticketno = false;
          }
          console.log(
            this.bookingStatus,
            "bookingStatusbookingStatusbookingStatus"
          );
          this.bookingTime = moment(v.booking_at).format("DD MMM YYYY HH:MM");
          this.searchcabintype = v.search_cabin_type;
          this.countrycode = v.contact_info.country_code;

          for (let i = 0; i < this.airlogodata.length; i++) {
            this.itineryData.forEach((a) => {
              if (this.airlogodata[i].id == a.carrier) {
                a.logo = this.airlogodata[i].logo;
                a.flightname = this.airlogodata[i].name;
              }
            });
          }

          console.log(this.datareurn, "this.datareurnthis.datareurn");

          Object.keys(userData).forEach((icao) => {
            const airport = userData[icao];
            if (airport.iata !== "") {
              this.fromAirports.push({
                iata: airport.iata,
                icao: airport.icao,
                places: `${airport.name}`,
              });
            }
          });

          for (let i = 0; i < this.fromAirports.length; i++) {
            this.itineryData.forEach((a) => {
              if (this.fromAirports[i].iata == a.depart_airport) {
                a.depAirportName = this.fromAirports[i].places;
              }
              if (this.fromAirports[i].iata == a.arrival_airport) {
                a.arrAirportName = this.fromAirports[i].places;
              }
            });
          }
          this.itineryData.forEach((j) => {
            console.log(this.itineryData, "yd999999999gvashyvveifkk");

            if (
              v.search_trip_type == "multi" ||
              this.itineryData[this.itineryData.length - 1].flight_line > 2
            ) {
              this.triptype = "MULTICITY";
              // alert('11')
              this.multi = true;
              if (j.flight_line == 1) {
                this.Flight1.push(j);
                console.log(this.Flight1, "11Flight1Flight1");
              }
              if (j.flight_line == 2) {
                this.Flight2.push(j);
                console.log(this.Flight2, "22");
              }
              if (j.flight_line == 3) {
                this.Flight3.push(j);
                console.log(this.Flight3, "33");
              }
              if (j.flight_line == 4) {
                this.Flight4.push(j);
                console.log(this.Flight4, "44");
              }
            } else if (
              v.search_trip_type == "roundtrip" ||
              this.itineryData[this.itineryData.length - 1].flight_line == "2"
            ) {
              // alert('1122')
              console.log("routtripe");
              this.rount = true;
              this.triptype = "ROUND TRIP";
              if (j.flight_line == 1) {
                this.depatureData.push(j);
              } else if (j.flight_line == 2) {
                this.arrivalData.push(j);
              }
            } else if (
              v.search_trip_type == "oneway" ||
              this.itineryData[this.itineryData.length - 1].flight_line == "1"
            ) {
              // alert('1133')
              console.log("oneway");
              this.oneway = true;
              this.triptype = "ONEWAY TRIP";
              this.depatureData.push(j);
            }
          });
          // this.itineryData.forEach((s) => {
          //   if (s.flight_line == 2) {

          //   } else if (s.flight_line == 1) {

          //   }
          // });
          console.log(this.depatureData, "this.depatureData");
          console.log(this.arrivalData, "this.depatureData");

          console.log(
            this.multicity,
            "this.multicitythis.multicitythis.multicity"
          );
        });
      }
      this.getBookingStatus();
      this.gettimedep();
    },

    gettimedep() {
      let result = [];

      for (let i = 0; i < this.itineryData.length - 1; i++) {
        console.log(this.itineryData[i].depart_datetime, "popppopoppopop");

        const time1 = new Date(this.itineryData[i + 1].depart_datetime);
        const time2 = new Date(this.itineryData[i].arrival_datetime);

        const diffInMilliseconds = Math.abs(time1 - time2);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        result.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }

      this.itineryData.forEach((v, j) => {
        v.overlayTime = result[j];
      });

      console.log(this.itineryData, "itineryDataitineryData");
    },

    getColor() {
      if (
        this.bookingStatus == "cancelled" ||
        this.bookingStatus == "ticketed"
      ) {
        return "danger";
      } else if (this.bookingStatus === "booked") {
        return "success";
      } else {
        return "warning";
      }
    },

    getBookingStatus(bookingStatus) {
      if (/[A-Z]/.test(bookingStatus)) {
        if (
          bookingStatus.toLowerCase() === "cancelled" ||
          bookingStatus.toLowerCase() === "ticketed"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (bookingStatus === "cancelled" || bookingStatus === "ticketed") {
          return false;
        } else {
          return true;
        }
      }
    },

    flighttimeData(data) {
      console.log(data.duration, Math.floor(data.duration / 60), "ppppprrrrr");
      const $time = Math.floor(data.duration / 60);
      return $time;
    },
    flighttimeData1(data) {
      const $min = data.duration % 60;
      return $min;
    },

    getConfig() {
      // let getConfigData = this.$store.state.data

      const getConfigData = getConfigDataFromLocalStorage();

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      if (getConfigData) {
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;

        this.portal_sitetitle =
          getConfigData.payload.portal_configuration.site_title;
        this.modifyBookingUrl =
          getConfigData.payload.portal_configuration.API_endpoints.modify_booking;
        this.portalId = getConfigData.payload.portal_configuration.portal_id;

        if (this.getPassid.includes("env")) {
          this.retreiveBookApi =
            getConfigData.payload.portal_configuration.API_endpoints.meta_retreive;
          console.log(
            this.retreiveBookApi,
            "retreiveBookApiretreiveBookApi....1"
          );
        } else {
          this.retreiveBookApi =
            getConfigData.payload.portal_configuration.API_endpoints.retreive_booking;
          console.log(
            this.retreiveBookApi,
            "retreiveBookApiretreiveBookApi....2"
          );
        }
      }
    },

    async retrivingBooking() {
      this.booking_refId = JSON.parse(this.$route.query.booking_id);
      let retreivebooking = {
        request_type: "retreive_booking",
        booking_id: this.booking_refId.booking_Id,
        pnr: this.booking_refId.pnr,
        last_name: this.booking_refId.last_name,
        portal_id: this.portalId,
      };

      console.log(retreivebooking, "retreivebookingretreivebooking");

      await axios.post(this.retreiveBookApi, retreivebooking, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response, "vasanth");
          if (response.data) {
            setTimeout(() => {
              this.overlay = false;
              this.showConfirmPage = true;
            }, 5000);
          }

          console.log(response.data, "retrievebooking555555555.........2999");
          this.retriveData.push(response.data);

          if (this.retriveData.length > 0) {
            this.bookingretrive();
          }
        })
        .catch((error) => {
          console.log(error, "retreivebooking......2");
          this.showConfirmPage = false;

          setTimeout(() => {
            this.pageloader = true;
            this.dialog3 = true;
          }, 10000);
        });

      this.local_id = this.$route.query.pass_id;
      this.Localoneway_id = this.$route.query.pass_uid;
      localStorage.setItem("delete_Id", this.Localoneway_id);
      localStorage.setItem("delete_Id", this.local_id);
    },
  },

  mounted() {
    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    console.log(this.dateOfBirth, "dobdob.....");

    // const s1 = document.createElement("script");
    // const s0 = document.getElementsByTagName("script")[0];
    // s1.async = true;
    // s1.src = 'https://embed.tawk.to/661e1b5f65e07e/1hnlmkj';
    // s1.charset = 'UTF-8';
    // s1.setAttribute('crossorigin', '*');
    // s0.parentNode.insertBefore(s1, s0);
  },

  watch: {
    bookstatus(newVal) {
      console.log(newVal, "newVal");
      if (newVal == "Cancel Booking") {
        this.datechange = false;
        this.requstup = false;
      } else if (newVal == "Date Change") {
        this.datechange = true;
        this.requstup = false;
      } else if (newVal == "Upgrade Booking" || newVal == "Request Addons") {
        this.datechange = false;
        this.requstup = true;
      }
    },
  },

  created() {
    this.overlay = true;
    this.getPassid =
      this.$route.query.pass_id ||
      this.$route.query.pass_uid ||
      this.$route.query.booking_id;
    // console.log(this.getPassid, "this.getPassidthis.getPassid....1");
    // console.log(this.$route.query.pass_id, "this.getPassidthis.getPassid....2");
    // console.log(
    //   this.$route.query.pass_uid,
    //   "this.getPassidthis.getPassid....3"
    // );
    // console.log(
    //   this.$route.query.booking_id,
    //   "this.getPassidthis.getPassid....4"
    // );

    this.getConfig();

    this.retrivingBooking();

    // this.bookingretrive()
  },
};
</script>

<style scoped>
/* .booking-card{
    font-family: sans-serif;
  } */

@import "@/assets/main.css";


p,
h6,
h5,
h4,
h3,
h2,
h1 {
  margin: 0;
}

.f-size-20 {
  font-size: 20px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.iconSize {
  font-size: 24px;
}

.ItinHead {
  font-size: 16px;
}

.silverColor {
  color: silver;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}



/* .booking-text p{
    font-size: 17px;
    font-weight: 400;
  } */

>>>.checkBox .v-selection-control__wrapper {
  width: 20px !important;
}

>>>.managePopup .v-btn .v-btn__content {
  font-size: 14px;
  font-weight: 600;
}

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.Booking-status {
  font-size: 12px;
  text-transform: uppercase;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

.trip1 {
  justify-content: center;
  display: flex;
  padding: 15px;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

/* .f-family {
    font-family: sans-serif;
    font-size: 15px;
  } */

.MyAirBudget1 {
  display: none;
}

/* Departure & Return Section */





>>>.v-textarea .v-field--no-label textarea,
.v-textarea .v-field--active textarea {
  opacity: 1;
  height: 50px;
}

>>>.dp__pointer {
  cursor: pointer;
  font-size: 13px;
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  /* margin: 0px 7px; */
  color: green;
  outline: 1px solid;
  width: 100px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}











@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .print-btn-1 {
    display: flex;
  }

  .print-btn-mbl {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }

  .print-btn-mbl {
    display: block;
  }

  .print-btn-1 {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .hide-btn-desk .desk-Btn {
    display: none !important;
  }

  .mbl-Btn {
    display: block !important;
  }
}

@media only screen and (min-width: 577px) {
  .mbl-Btn {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  .mobile-table {
    display: none;
  }

  /* .desk-Btn {
      display: block !important;
    } */
}

@media only screen and (max-width: 767px) {
  .iconSize {
    font-size: 18px;
  }

  .ItinHead {
    font-size: 13px;
  }

  .arrMargin {
    margin-top: 12px;
  }
}

/* @media only screen and (max-width: 600px) {
    .Booking-id {
      font-size: 18px;
      font-weight: 700;
    }
  
    .Booking-status {
      font-size: 12px;
      text-transform: uppercase;
    }
  
    .mbl-Btn {
      display: block !important;
    }
  
    .desk-Btn {
      display: none !important;
    }
  
    .contion {
      display: block;
    }
  
    .MyAirBudget {
      display: none;
    }
  
    .MyAirBudget1 {
      display: block;
    }
  
    .mobile-table {
      display: block;
    }
  
    .print-btn-1 {
      display: none;
    }
  
    .f-family {
      font-size: 12px;
    }
  
    .airlogo-size {
      width: 25px !important;
    }
  
    .airline-name {
      font-size: 10px;
    }
  
    .f-size-16 {
      font-size: 16px;
    }
  
    .TERMS {
      display: none;
    }
  
    .f-size-13 {
      font-size: 13px;
    }
  
    .f-size-12 {
      font-size: 12px;
    }
  
    .f-size-11 {
      font-size: 11px;
    }
  
    .f-size-10 {
      font-size: 10px;
    }
  
    .f-size-9 {
      font-size: 10px;
    }
  
    .fw-500 {
      font-weight: 600;
    }
  } */



@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.view_mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .f-size-22{
    font-size: 18px;
    font-weight: 500;
  }

  .f-size-20 {
    font-size: 14px;
    font-weight: 500;
  }

  .f-size-14 {
    font-size: 10px;
  }

  .f-size-13 {
    font-size: 12px;
  }

  .f-size-12 {
    font-size: 10px;
    font-weight: 500;
  }

  .fw-500 {
    font-weight: 600;
  }

  .view_mobile {
    display: block;
  }

  .view_tap {
    display: none;
  }

  .bookingInfoCard {
    padding: 0px 4px !important;
  }

  .bookTripId-info {
    width: 35% !important;
  }

  .bookPlace-info {
    width: 65% !important;
  }

  .bookDate-info {
    width: 40% !important;
  }

  .bookTrip-info {
    width: 40% !important;
  }

  .pass-icon {
    margin-left: 0px !important;
  }

  .pass-name {
    margin-left: 4px !important;
  }

  .pass-info-1 {
    width: 68%;
  }

  .pass-info-2 {
    width: 32%;
  }

  .price_margin {
    margin-bottom: 12px;
  }

  .margin-less {
    margin: 0px;
  }
}

.header-con {
  font-size: 15px;
  font-weight: 500;
}









.pageSection .border-bottom {
  border-bottom: 4px solid black;
}

.table tr th{
  font-weight:500;
}

.table tr td{
  font-size:14px;
  font-weight:400;
}
</style>